import * as mdb from 'mdb5-pro/js/mdb.min.js';
import moment, { relativeTimeRounding } from 'moment';
import 'moment/locale/fr.js'; // use french



function initTicDateWidgets() {
	let ticdates = $('.tic-datepicker');
	ticdates.each( function(index){
		var input_id = $(this).find('input:first').attr('id');
		$('#'+input_id).hide()
		var picker_id = input_id + '_picker';

		// Init date
		if($("#"+picker_id+" input").val() != "")
			$('#'+input_id).val( moment($("#"+picker_id+" input").val(), "DD/MM/YYYY").format('YYYY-MM-DD') );



		var label = $(this).find('label');
		label.attr('for', input_id + "_picker_input")
		label.remove()
		label.appendTo( $('#'+picker_id ) )

		// Construct datepicker
		const datepickerEl = document.getElementById(picker_id)
		var format = 'dd/mm/yyyy'
		const options = { 
                        format: format,
                        title: 'Sélectionner la date',
                        monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                        monthsShort: ['Jan', 'Févr', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov','Déc'],
						weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
						weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
						weekdaysNarrow: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
						startDay: 1,
                        okBtnText: 'Ok',
                        clearBtnText: 'Effacer',
                        cancelBtnText: 'Fermer',
			inline: false,
 
		}
		const datepicker = new mdb.Datepicker(datepickerEl, options)

		datepickerEl.addEventListener('close.mdb.datepicker', (e) => {
			// do something...
			//console.log( moment($("#"+picker_id+" input").val()).format('yyyy-mm-dd') )
			if($("#"+picker_id+" input").val()) $('#'+input_id).val( moment($("#"+picker_id+" input").val(), "DD/MM/YYYY").format('YYYY-MM-DD') );
			else $('#'+input_id).val('')
		})
		

	});
}

// Export function outside of Webpack
window.initTicDateWidgets = initTicDateWidgets;

jQuery(document).ready(function() {
	initTicDateWidgets()
});
