/**
 * Intégration de JQuery avec Webpack.
 * https://symfony.com/doc/current/frontend/encore/legacy-applications.html
 */

import $ from 'jquery';

// create global $ and jQuery variables (pour usage depuis scripts hors-webpack)
global.$ = global.jQuery = $;

