import * as mdb from "mdb5-pro";

jQuery(document).ready(function(){
	initWysiwyg();
});


function initWysiwyg() {
	var name = jQuery('div.wysiwyg textarea').attr('name');
	var value = jQuery('div.wysiwyg textarea').val();
	jQuery('div.wysiwyg').empty();
	jQuery('div.wysiwyg').html(value);
	jQuery('div.wysiwyg').wysiwyg();
	// Ajout de la valeur récupéré à l'init du formulaire pour évité les erreurs de type emptyBody
	jQuery('div.wysiwyg').siblings('textarea').attr('name', name).val(value);
}

window.initWysiwyg = initWysiwyg;

/*
jQuery(document).ready(function(){
        let ticlists = $('.summernote');
        ticlists.each( function(index){
		var editor_id = $(this).attr('id');
		if (typeof jQuery.fn.summernote == "function") {
		jQuery('#'+editor_id).summernote({
			minHeight: 50,
			maxHeight: 900,
			height:    {{ height|default(null)|jsvalue }},
			focus:     {{ focus|default(false)|jsvalue }},
	{% if lang|default(false) %}
			lang:      '{{ lang }}',
	{% endif %}
	{% if attr.placeholder|default(false) %}
			placeholder: {{ ((attr.placeholder is same as(true)) ? form|labelize : attr.placeholder)|trans|jsvalue }},
	{% endif %}
	{% if toolbars|default(null) is not null %}
			toolbar: {{ toolbars|jsvalue }},
	{% endif %}
			imageAttributes: {
				icon:               '<i class="note-icon-pencil" />',
				removeEmpty:        false,
				disableUpload:      true
			},
			popover: {
				table: [
					['custom',      ['tableHeader', 'tableStyles']],
					['add',         ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
					['delete',      ['deleteRow', 'deleteCol', 'deleteTable']]
				],
				image: [
					['custom',      ['imageAttributes']],
					['imagesize',   ['imageSize100', 'imageSize50', 'imageSize25']],
					['float',       ['floatLeft', 'floatRight', 'floatNone']],
					['remove',      ['removeMedia']]
				]
			},
			popatmouse: false
		});
	{% if disabled|default(false) or readonly|default(false) %}
		jQuery('#{{ id }}').summernote('disable');
	{% endif %}
		}
	}
});
*/

