function ticPrint(printEl, title) {
        // Open a new window for the printable table
        var win = window.open( '', '' );

        if (! win) {
/*
                dt.buttons.info(
                        dt.i18n( 'buttons.printErrorTitle', 'Unable to open print view' ),
                        dt.i18n( 'buttons.printErrorMsg', 'Please allow popups in your browser for this site to be able to view the print view.' ),
                        5000
                );
*/
		console.log( 'Unable to open print view' )

                return;
        }

        win.document.close();

        // Inject the title and also a copy of the style and link tags from this
        // document so the table can retain its base styling. Note that we have
        // to use string manipulation as IE won't allow elements to be created
        // in the host document and then appended to the new window.
        // Affichage de la date du jour
        let date = new Date();
        let options = {year: "numeric", month: "2-digit", day: "2-digit"};
        let dateNow = date.toLocaleDateString("fr-FR", options);
        var head = '<title>'+title+ ' - ' +dateNow+'</title>';
        $('style, link').each( function () {
                head += _styleToAbs( this );
        } );

        try {
                win.document.head.innerHTML = head; // Work around for Edge
        }
        catch (e) {
                $(win.document.head).html( head ); // Old IE
        }

        // Inject datas and other surrounding information
        win.document.body.innerHTML =
//                        '<h1>'+$('title').text()+'</h1>'+
                $(printEl).html()

        $(win.document.body).addClass('print-view');

        $('img', win.document.body).each( function ( i, img ) {
                img.setAttribute( 'src', _relToAbs( img.getAttribute('src') ) );
        } );

        $(win.document.body).find('.second-title').each(function(index) {
                var content = $(this).clone();
                $(this).remove();
                $(win.document.body).find('.tab-content').prepend(content);
        });

	// Hide actions buttons
	$(win.document.body).find('.ticadmin-actions').each( function(index) {
		$(this).remove();
	});

	// Replace nav-tabs by cards
	$(win.document.body).find('.d-xl-block ul.nav-tabs li a').each( function(index) {
		var tab_id = $(this).attr('href');
		var tab_text = $(this).text();
		$(win.document.body).find(tab_id).each( function(index)	{
			$(this).prepend('<h4 class="print-title-tab card-header">' + tab_text + "</h4>");
		})
	});
	$(win.document.body).find('div.tab-content div.tab-pane div.row').each( function(index) {
		$(this).removeClass('margin-top-2')
		$(this).removeClass('row')
		$(this).addClass('card-body')
	});
	$(win.document.body).find('div.tab-content div.tab-pane').each( function(index) {
		$(this).removeClass('tab-pane')
		$(this).addClass('card')
	});
	$(win.document.body).find('ul.nav-tabs').remove();

        // remove tooltip
        $(win.document.body).find(".tooltip").each( function(index){
                $(this).addClass('d-none');
        });

        // show elements collapsed
        $(win.document.body).find(".collapse").each(function(index){
                $(this).addClass("show");
                $(this).removeClass("collapse");
        });

        // remove all elements non printables
        $(win.document.body).find(".hideForPrint").each( function(index) {
                $(this).remove();
        });

        // modify colspan for print
        $(win.document.body).find(".removeOneForPrint").each(function(index){
                $(this).attr('colspan', $(this).attr('colSpan')-1);
        });

        // modify css
        $(win.document.body).find("#home > .card-body").each(function(index) {
                $(this).css('display','flex');
                // remove logo and add it to the page title
                var logoNode = $(this).find('p').first();
                logoNode.addClass('col-sm-1');
                logoNode.css('text-align','center');
                $(win.document.body).find(".second-title").prepend(logoNode);
        });
        $(win.document.body).find("#home > .card-body > div").each(function(index) {
                $(this).css('display','inline-block');
                $(this).css('margin','5px');
                $(this).css('padding','5px');
                $(this).css('border-right','1px dotted grey');
                $(this).css('flex','1');
                $(this).removeClass('text-md-end');
                $(this).addClass('text-md-start');
                $(this).removeClass('col-md-4');
        });

        // special case for comments: remove the column and create new row below with colspan
        $(win.document.body).find(".createLineForPrint").each(function(index){
                var col = $(this).index(); // index of col
                var lengthTable = $(this).closest('thead').find('th').length;
                var title = $(this).text();
                // get tbody and parse rows
                var tbody =$(this).closest('table').find('tbody');
                tbody.find('tr').each(function(index) {
                        var cell = $(this).find('td')[col];
                        if(cell) {
                                var content = $(cell).html();
                                cell.remove();
                                // create new row
                                if(lengthTable>2){
                                        $(this).after('<tr><td colspan="2" class="text-md-end">'+title+'</td><td colspan="'+(lengthTable-3)+'">'+content+'</td></tr>');
                                }
                                else 
                                        $(this).after('<tr><td class="text-md-end">'+title+'</td><td>'+content+'</td></tr>');
                        }
                        
                });
                $(this).remove();
        });

        $(win.document.body).find('.table-dark').each(function(index) {
                $(this).removeClass('table-dark');
                $(this).addClass('table-active');
        });

        $(win.document.body).find("sup").each(function(index) {
                $(this).before(":"+$(this).text());
                $(this).remove();
        });

        // Allow stylesheets time to load
        var autoPrint = function () {
               win.print(); // blocking - so close will not
               win.close(); // execute until this is done
        };

        if ( navigator.userAgent.match(/Trident\/\d.\d/) ) { // IE needs to call this without a setTimeout
                autoPrint();
        }
        else {
                win.setTimeout( autoPrint, 1000 );
        }
}

// Export function outside of Webpack
window.ticPrint = ticPrint;



/**
 * Clone link and style tags, taking into account the need to change the source
 * path.
 *
 * @param  {node}     el Element to convert
 */
var _styleToAbs = function( el ) {
        var url;
        var clone = $(el).clone()[0];
        var linkHost;

        if ( clone.nodeName.toLowerCase() === 'link' ) {
                clone.href = _relToAbs( clone.href );
        }

        return clone.outerHTML;
};

/**
 * Convert a URL from a relative to an absolute address so it will work
 * correctly in the popup window which has no base URL.
 *
 * @param  {string} href URL
 */
var _relToAbs = function( href ) {
        // Assign to a link on the original page so the browser will do all the
        // hard work of figuring out where the file actually is
        _link.href = href;
        var linkHost = _link.host;

        // IE doesn't have a trailing slash on the host
        // Chrome has it on the pathname
        if ( linkHost.indexOf('/') === -1 && _link.pathname.indexOf('/') !== 0) {
                linkHost += '/';
        }

        return _link.protocol+"//"+linkHost+_link.pathname+_link.search;
};


var _link = document.createElement( 'a' );
