/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Intégration de JQuery
import '../vendor/teicee/core-bundle/assets/import_jquery.js'
// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';

import 'jquery-ui-sortable'

import * as mdb from '../node_modules/mdb5-pro/js/mdb.min.js'; // lib
window.mdb = mdb;
import * as mdbfileuploadsplugin from '../node_modules/mdb5-pro/plugins/js/file-upload.min.js';

window.fileuploadsplugin = mdbfileuploadsplugin;
import * as mdbinputmaskplugin from '../node_modules/mdb5-pro/plugins/js/inputmask.min.js';

window.Inputmask = mdbinputmaskplugin;
import * as wysiwygPlugin from '../node_modules/mdb5-pro/plugins/js/wysiwyg.min.js';
// import { Input } from '../node_modules/mdb5-pro/js/mdb.min.js'; // module
// import { Input as CustomInput } from '../node_modules/mdb5-pro/js/mdb.min.js'; // module with custom name

import moment, {relativeTimeRounding} from 'moment';
import 'moment/locale/fr.js'; // use french


import 'jszip';
// import 'pdfmake'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

import 'datatables.net';
import 'datatables.net-bs5';
import 'datatables.net-fixedheader-bs5';
import './datatables-buttons.mdb.js';
//import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import './datatables-responsive.mdb.js';


// lancement des scripts de la page (inline via block 'page_script') après chargement
//$(document).ready(function() { if (typeof mdbPageStarter == "function") mdbPageStarter(); });


import './menu.js'

window.moment = moment;

//========================= Validation des formulaires  =========================//

// Fetch all the forms we want to apply custom Bootstrap validation styles to
const forms = document.querySelectorAll('.needs-validation');
if (forms !== 'undefined' && forms !== null) {
// Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach((form) => {
        form.addEventListener('submit', (event) => {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}

//=================== ajout FA check et times au inputs =========================//

/* TODO: à mettre dans une fonction pour pouvoir réutiliser ailleurs */
var unvalidState = document.getElementsByClassName("invalid-feedback");
var validState = document.getElementsByClassName("valid-feedback");

var faUnvalid = '<i class="fas fa-times"></i>';
var faValid = '<i class="fas fa-check"></i>';

for (let uvs of unvalidState) {
    uvs.insertAdjacentHTML('beforeend', faUnvalid);
}
for (let vs of validState) {
    vs.insertAdjacentHTML('beforeend', faValid);
}

//========================== Fin Validation des formulaires  ======================//


function initMDBInputs(element) {
    console.log('init MDB');
    // Mise en forme CSS sur les input + focus
    element.querySelectorAll('.form-outline').forEach((formOutline) => {
        new mdb.Input(formOutline).init();
    });
    // Mise en forme CSS sur les select
    element.querySelectorAll('select').forEach((select) => {
        new mdb.Select(select);
    });

    // Mise en forme CSS sur les imput file
    element.querySelectorAll('.file-upload-input').forEach((fileUploadEl) => {
        new fileuploadsplugin(fileUploadEl, {
            'removeBtn': 'Supprimer',
            'defaultMsg': 'Glisser/déposer un fichier ou cliquer ici',
            'previewMsg': 'Glisser/déposer ou cliquer pour remplacement'
        });
    });

    // Mise en forme sur les dates
    initTicDateWidgets();

    // Mise en forme CSS sur les input + focus
    element.querySelectorAll('.tic-input-mask').forEach((input) => {
        new Inputmask(input);
    });

}


// Export function outside of Webpack
window.initMDBInputs = initMDBInputs;

//=========================== All admin tables ===========================//

const showModalEl = document.getElementById('ticadmin-show-modal');
const showModalElBis = document.getElementById('ticadmin-show-modal-bis');
const editModalEl = document.getElementById('ticadmin-edit-modal');
const listModalEl = document.getElementById('ticadmin-list-modal');
var editModal;
var listModal;
var showModal;
var showModalBis;

// Init modal principale
if (editModalEl !== 'undefined' && editModalEl !== null) {

    editModalEl.addEventListener('confirm.mdb.popconfirm', () => {
        var event = new Event('submit', {
            'bubbles': true, // Whether the event will bubble up through the DOM or not
            'cancelable': true  // Whether the event may be canceled or not
        });
        editModalEl.querySelector('form').dispatchEvent(event);
    });
    editModal = new mdb.Modal(editModalEl);
}

if (showModalEl !== 'undefined' && showModalEl !== null) {
    showModal = new mdb.Modal(showModalEl);
}

// Init modal secondaire
if (showModalElBis !== 'undefined' && showModalElBis !== null) {
    showModalBis = new mdb.Modal(showModalElBis);
}

if (listModalEl !== 'undefined' && listModalEl !== null) {
    listModal = new mdb.Modal(listModalEl);
}

const arrayRequestsUpdateName = [
    'admin_archive_typelot',
    'admin_archive_subcontractor',
    'account_validation',
    'admin_archive_admin',
    'admin_archive_project',
    'admin_projectclosed_form',
    'subcontractor'
];
var button = undefined;


const setAdminDtActions = (mytable, bool = false) => {
    // btn enable/disable
    mytable.querySelectorAll('button').forEach(btn => {
        new mdb.Tooltip(btn, {container: 'body'})
    });
    mytable.querySelectorAll('a.button').forEach(btn => {
        new mdb.Tooltip(btn, {container: 'body'})
    });

    mytable.querySelectorAll('#btnEdit').forEach(btnEdit => {
        new mdb.Tooltip(btnEdit, {container: 'body'})
    });

    mytable.querySelectorAll('#btnShow').forEach(btnShow => {
        new mdb.Tooltip(btnShow, {container: 'body'})
    });

    mytable.querySelectorAll('#btnList').forEach(btnList => {
        new mdb.Tooltip(btnList, {container: 'body'})
    });

    // Modal show
    mytable.getElementsByClassName('ticadmin-show-modal-btn').forEach(btn => {
        putEventListenerOnModalShow(btn);
    });

    // Modal list
    mytable.getElementsByClassName('ticadmin-list-modal-btn').forEach(btn => {
        putEventListenerOnModalList(btn)
    });

    // Modal edit/create
    mytable.getElementsByClassName('ticadmin-edit-modal-btn').forEach(btn => {
        putEventListenerOnModalEdit(btn);
    });

    mytable.getElementsByClassName('ticadmin-archive-btn').forEach(btn => {
        putEventListenerOnArchiveBtn(btn, bool);
    });

    mytable.getElementsByClassName('ticadmin-delete-modal-btn').forEach(btn => {
        putEventListenerOnDelete(btn);
    })
};
const setSortabledFormat = (cell, value) => {
    cell.innerText = moment(value).format('DD/MM/YYYY HH:mm:ss');
}

function generateDtActions(mytableid) {
    const mytable = document.getElementById(mytableid);
    if (mytable !== 'undefined' && mytable !== null) {
        setAdminDtActions(document.querySelector('#ticadmin-actions'));
        mytable.addEventListener('render.mdb.datatable', () => setAdminDtActions(mytable, true));
        let dtInstance = new mdb.Datatable(mytable, {}, {
            pagination: false,
            rowsText: 'Lignes par page :',
            striped: true,
            noFoundMessage: 'Aucun résultat'
        }); // force reinit
        const columns = dtInstance._columns;
        columns.forEach(function (item) {
            if (item.field === 'date-sort') {
                item.format = setSortabledFormat;
            }
        });
        dtInstance.update();
        if (document.getElementById('datatable-search-input')) {
            document.getElementById('datatable-search-input').addEventListener('input', (e) => {
                dtInstance.search(e.target.value);
            });
            if (document.getElementById('datatable-clean-search')) {
                document.getElementById('datatable-clean-search').addEventListener('click', (e) => {
                    document.getElementById('datatable-search-input').value = '';
                    dtInstance.search('');
                });

            }
        }
    } else {
        resetAdminDtActions();
    }

}

generateDtActions('ticadmin-datatable');

function resetAdminDtActions() {
    document.querySelectorAll('.ticadmin-actions').forEach(div => {
        setAdminDtActions(div);
    })

}

window.computeFixedTableSize = function () {
    var elem = $(".fixedHeaderContainer");
    var hideOverflow = false;
    if (elem.length) {
        var screenH = window.innerHeight;
        elem.each(function (index) {
            if ($(this).is(':visible')) {
                var maxH = Math.max(200, screenH - $(this).offset().top);
                $(this).css('max-height', parseInt(maxH) + 'px');
                hideOverflow = true;
            }
        });
    }
    if (hideOverflow) {
        $('html').css('overflow', 'hidden');
    } else
        $('html').css('overflow', 'visible');
}

jQuery(document).ready(function () {

    computeFixedTableSize();
    var ticTable = $('.tic-datatable').DataTable({
        "language": {"url": "https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json"},
        //dom: 'Bfrtip',
        "responsive": true,
        fixedHeader: true,
        "order": [],
        "paging": false,
        "info": false,
        "dom":
        // "<'row'<'col-xs-12 col-sm-6 col-md-6 datatable-logic-button-container'l id='test'><'d-none d-md-block col-xs-12 col-md-6 text-end'B>>" +
            "<'row'<'col-xs-12 col-sm-12 col-md-12'f>>" +
            "<'row'<'col-sm-12 g-1 g-md-6'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        "buttons": [
            'copyHtml5',
            'excelHtml5',
            //'pdfHtml5',
            {
                extend: 'pdfHtml5',
                orientation: 'landscape',
                exportOptions: {
                    columns: ':not(.noprint)',
                },
                customize: function (doc) {
                }
            },
            //'print'
            {
                extend: 'print',
                exportOptions: {
                    columns: ':not(.noprint)',
                },
                customize: function (win) {
                    $(win.document.body)
                        .css('font-size', '10pt')
                        .prepend(
                            '<img src="https://kleidi.fr/media/original.svg" style="position:absolute; top:0; left:0; opacity: 0.1;" />'
                        );
                    $(win.document.body).find('table')
                        .addClass('compact')
                        .css('font-size', 'inherit');
                }
            }
        ],
        "initComplete": function (settings, json) {
            //ticTable.buttons().container().appendTo( $('.col-md-6:eq(0)', ticTable.table().container() ) );
            //new mdb.Select($('#'+settings.sTableId+"_length select")[0])

            // Custom search filters
            var filters = $(".dataTables_filter");
            var selOpts = {};
            if (!filters.length) return;
            this.api().columns('[class*="-filter"]').every(function () {
                var column = this;
                var header = $(column.header());
                var cclass = header.attr('class');
                var title = header.attr('title');
                if (title === undefined) title = '';
                var label = header.attr('data-label');
                if (label === undefined) label = header.text();
                var cname = header.attr('data-name');
                if (cname === undefined) cname = label;
                var cid = "dt-filter-" + cname

                var col = $('<div class="col-sm"></div>').appendTo(filters.show());


                // génération du sélecteur listant les possibilités
                if (cclass.indexOf('sel-filter') >= 0) {
                    var select = $('<select />', {
                            id: cid,
                            name: cname,
                            class: "select form-control form-control-sm placeholder"
                        })
                            .appendTo(col)
                            .on('change', function () {
                                var pattern = $(this).val();
                                if (pattern == '[_ALL_]') {
                                    $(this).addClass('placeholder');
                                    return column.search('', false).draw();
                                }
                                $(this).removeClass('placeholder');
                                column.search(pattern, true, false, false).draw();
                            })
                            //.append( $('<option value="[_ALL_]" class="placeholder">' + '--- ' + label + ' ---' + '</option>') )
                            .append($('<option value="[_ALL_]" class="placeholder">Tous</option>'))
                    ;
                    // liste des choix du filtre (déterminée automatiquement par le contenu)
                    if (!(cname in selOpts)) {
                        selOpts[cname] = {};
                        column.cache('search').sort().unique().each(function (value) {
                            var label = value, data = value.split('=', 2);
                            if (data.length == 2) {
                                label = data[1];
                                value = data[0];
                            }
                            if (label !== '') selOpts[cname][value] = label;
                        });
                    }
                    for (var value in selOpts[cname]) {
                        if (value === '[_EMPTY_]') value = '';
                        var pattern = '^' + jQuery.fn.dataTable.util.escapeRegex(value) + '$';
                        $('<option />', {
                            'value': pattern,
                            'data-value': value,
                            'text': selOpts[cname][value]
                        }).appendTo(select);
                    }
                    var label = $('<label class="form-label select-label">' + label + '</label>')
                        .appendTo(col)
                    const mySelect = new mdb.Select(document.getElementById(cid))

                }

                // génération du champs full-text (pattern contains)
                if (cclass.indexOf('ctxt-filter') >= 0) {
                    var div = $('<div />', {class: 'form-outline', id: 'form-' + cid});
                    var input = $('<input />', {
                            id: cid,
                            name: cname,
                            value: value,
                            title: title,
                            type: "text",
                            class: "form-control"
                        })
                            .appendTo(div)
                            .on('change keyup', function () {
                                var pattern = $(this).val();
                                if (pattern == '') return column.search('', false).draw();
                                column.search(pattern, false, false, true).draw();
                            })
                    ;
                    var label = $('<label class="form-label" for="' + cid + '">' + label + '</label>')
                        .appendTo(div)
                    div.appendTo(col);
                    new mdb.Input(document.getElementById('form-' + cid)).init();
                }

                // génération du champs full-text (pattern starts with)
                if (cclass.indexOf('ltxt-filter') >= 0) {
                    var div = $('<div />', {class: 'form-outline', id: 'form-' + cid});
                    var input = $('<input />', {
                            id: cid,
                            name: cname,
                            value: value,
                            title: title,
                            type: "text",
                            class: "form-control"
                        })
                            .appendTo(div)
                            .on('change keyup', function () {
                                var pattern = $(this).val();
                                if (pattern == '') return column.search('', false).draw();
                                column.search('^' + jQuery.fn.dataTable.util.escapeRegex(pattern), true, false, true).draw();
                            })
                    ;
                    var label = $('<label class="form-label" for="' + cid + '">' + label + '</label>')
                        .appendTo(div)
                    div.appendTo(col);
                    new mdb.Input(document.getElementById('form-' + cid)).init();
                }

                // génération du sélecteur booléen
                if (cclass.indexOf('bool-filter') >= 0) {
                    var select = $('<select />', {
                            id: cid,
                            name: cname,
                            class: "form-control" + ((value == '') ? " placeholder" : "")
                        })
                            .appendTo(col)
                            .on('change', function () {
                                var pattern = $(this).val();
                                if (pattern == '') {
                                    $(this).addClass('placeholder');
                                    return column.search('', false).draw();
                                }
                                $(this).removeClass('placeholder');
                                column.search(pattern, true, false, false).draw();
                            })
                            .append($('<option value="" class="placeholder">' + label + ' ?</option>'))
                            .append($('<option value=".+"' + ((value == '.+') ? " selected" : "") + '>Oui</option>'))
                            .append($('<option value="^$"' + ((value == '^$') ? " selected" : "") + '>Non</option>'))
                    ;
                    var label = $('<label class="form-label select-label">' + label + '</label>')
                        .appendTo(col)
                    const mySelect = new mdb.Select(document.getElementById(cid))
                }


            });

            $('#' + settings.sTableId + "_filter").addClass('row')
            $('#' + settings.sTableId + "_filter").addClass('mb-1 mt-2')

            // search = mdb style
            var search_label = $('#' + settings.sTableId + "_filter input[type=search]").parent();
            var search_input = $('#' + settings.sTableId + "_filter input[type=search]");

            $('#' + settings.sTableId + "_filter").prepend('<div class="col-sm"><div class="form-outline"></div></div>');
            search_input.detach();
            search_input.removeClass('form-control-sm');
            $('#' + settings.sTableId + "_filter div.form-outline:first").append(search_input);
            search_label.detach();
            $('#' + settings.sTableId + "_filter div.form-outline:first").append(search_label);
            search_label.addClass('form-label')
            $('#' + settings.sTableId + "_filter div.form-outline:first").input()
        }
    });
    $(window).resize(function () {
        $("table.dataTable").DataTable().responsive.recalc();
        // ajustement de la hauteur des tableaux
        computeFixedTableSize();
    });
});


//===========================  Gestion ajout Client en ajax ===========================//

/**
 *  Action de sauvegarde des Clients en BDD en ajax
 **/
function saveClient(form) {
    // Construction d'un array à index - name_propriété => value
    var arrayValues = new Object();

    arrayValues = {
        "number": "",
        "company": "",
        "address": "",
        "postCode": "",
        "city": "",
        "activity": "",
        "email": "",
        "phone": "",
        "parentCompany": "",
        "accountNumber": "",
        "comment": "",
        "enabled": "",
        "physicalPersonName": "",
        "contactPhone": "",
        "contactMail": "",
        "contact": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "client_number":
                arrayValues.number = value;
                break;
            case "client_company":
                arrayValues.company = value;
                break;
            case "client_address":
                arrayValues.address = value;
                break;
            case "client_postCode":
                arrayValues.postCode = value;
                break;
            case "client_city":
                arrayValues.city = value;
                break;
            case "client_activity":
                arrayValues.activity = value;
                break;
            case "client_email":
                arrayValues.email = value;
                break;
            case "client_phone":
                arrayValues.phone = value;
                break;
            case "client_parentCompany":
                arrayValues.parentCompany = value;
                break;
            case "client_accountNumber":
                arrayValues.accountNumber = value;
                break;
            case "client_comment":
                arrayValues.comment = value;
                break;
            case "client_enabled":
                arrayValues.enabled = item.checked ? "1" : "0";
                break;
            case "client_physicalPersonName":
                arrayValues.physicalPersonName = value;
                break;
            case "client_contactPhone":
                arrayValues.contactPhone = value;
                break;
            case "client_contactMail":
                arrayValues.contactMail = value;
                break;
            case "client_contact":
                arrayValues.contact = value;
                break;
            case "client_accountingName":
                arrayValues.accountingName = value;
                break;
            case "client_accountingPhone":
                arrayValues.accountingPhone = value;
                break;
            case "client_accountingMail":
                arrayValues.accountingMail = value;
                break;
        }
    });

    return arrayValues;
}

//===========================  FIN Gestion ajout Client en ajax ===========================//


//===========================  Gestion ajout d'un lot en ajax après la création de projet ===========================//

/**
 *  Action de sauvegarde des Lots en BDD en ajax
 **/
function saveLot(form) {
    // Construction d'un array à index - name_propriété => value
    var arrayValues = new Object();

    arrayValues = {
        "typeLotType": "",
        "typeLotLibelle": "",
        "forecast": "",
        "forecastP1": "",
        "P0": "",
        "comment": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_lot_form_typeLotType":
                arrayValues.typeLotType = value;
                break;
            case "admin_lot_form_typeLotLibelle":
                arrayValues.typeLotLibelle = value;
                break;
            case "admin_lot_form_forecast":
                arrayValues.forecast = item.checked ? "1" : "0";
                break;
            case "admin_lot_form_forecastP1":
                arrayValues.forecastP1 = value;
                break;
            case "admin_lot_form_P0":
                arrayValues.P0 = value;
                break;
            case "admin_lot_form_comment":
                arrayValues.comment = value;
                break;
        }
    });

    return arrayValues;
}

//===========================  FIN Gestion ajout d'un lot en ajax après la création de projet ===========================//


//===========================  Gestion ajout attribution d'un lot en ajax ===========================//

/**
 *  Action de sauvegarde des Attributions en BDD en ajax
 **/
function saveAssigment(form) {
    // Construction d'un array à index - name_propriété => value
    var arrayValues = new Object();

    arrayValues = {
        "subcontractor": "",
        "budgets": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_assigmentlot_form_subcontractor":
                arrayValues.subcontractor = value;
                break;
            case "admin_assigmentlot_form_amount":
                arrayValues.budgets = value;
                break;
        }
    });

    return arrayValues;
}


//===========================  FIN Gestion ajout attribution d'un lot en ajax ===========================//


//===========================  Gestion ajout attribution d'un lot en ajax ===========================//

/**
 *  Action de sauvegarde de la cloture de project en BDD en ajax
 **/
function saveClosedProject(form) {
    // Construction d'un array à index - name_propriété => value
    const formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case "admin_projectclosed_form_PVrecette":
                formData.append('PVrecette', item.files[0]);
                break;
            case "admin_projectclosed_form_receiptDate":
                formData.append('receiptDate', value);
                break;
        }
    });
    return formData;
}

//===========================  Gestion validation des documents administratifs via Ajax ===========================//


/**
 * Fonction permettant de créer le body de la requête post pour
 * @param form
 */
function saveSubcontractor(form) {
    const formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case 'subcontractor_number':
                formData.append(field, value);
                break;
            case 'subcontractor_companyName':
                formData.append(field, value);
                break;
            case 'subcontractor_accountNumber':
                formData.append(field, value);
                break;
            case 'subcontractor_siren':
                formData.append(field, value);
                break;
            case 'subcontractor_tvaNumber':
                formData.append(field, value);
                break;
            case 'subcontractor_address':
                formData.append(field, value);
                break;
            case 'subcontractor_city':
                formData.append(field, value);
                break;
            case 'subcontractor_postCode':
                formData.append(field, value);
                break;
            case 'subcontractor_iban':
                formData.append(field, value);
                break;
            case 'subcontractor_bic':
                formData.append(field, value);
                break;
            case 'subcontractor_ibanFile':
                formData.append(field, item.files[0]);
                break;
            case 'subcontractor_salesContactName':
                formData.append(field, value);
                break;
            case 'subcontractor_salesContactPhone':
                formData.append(field, value);
                break;
            case 'subcontractor_salesContactMail':
                formData.append(field, value);
                break;
            case 'subcontractor_accountingContactName':
                formData.append(field, value);
                break;
            case 'subcontractor_accountingContactPhone':
                formData.append(field, value);
                break;
            case 'subcontractor_accountingContactMail':
                formData.append(field, value);
                break;
            case 'subcontractor_corpsEtat':
                let options = $('#subcontractor_corpsEtat option:selected');
                let array = {};
                options.each(function (index) {
                    array[index] = $(this).val();

                });
                formData.append(field, JSON.stringify(array));
                break;
            case 'subcontractor_enabled':
                formData.append(field, item.checked === true ? 1 : 0);
                break;
            case 'subcontractor_blacklist':
                formData.append(field, item.checked === true ? 1 : 0);
                break;
            case 'subcontractor_pinklist':
                formData.append(field, item.checked === true ? 1 : 0);
                break;
            case 'subcontractor_comments':
                formData.append(field, value);
                break;
            case 'subcontractor__token':
                formData.append(field, value);
                break;
        }
    });
    return formData;
}

/**
 * Fonction permettant de créer le body de la requête post pour valider un sous-traitant
 * @param  form
 */
function accountValidationSubcontractor(form) {
    const formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case 'account_validation_ibanFile':
                formData.append('imageFile', item.files[0]);
                break;
            case 'account_validation_accountNumber':
                formData.append('accountNumber', value);
                break;

        }
    });

    return formData;
}

/**
 * Action de réception d'un projet
 * @param  form
 * @returns
 */
function closeProject(form) {
    const formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case 'admin_projectclosed_form_receiptDate':
                formData.append('receiptDate', value);
                break;

            case 'admin_projectclosed_form_PVrecette':
                formData.append('imageFile', item.files[0]);
                break;

            default:
                break;
        }
    });
    return formData;
}

//===========================  FIN Gestion validation des documents administratifs via Ajax ===========================//

//========================================  Gestion Des avenants via Ajax =============================================//

/**
 *  Récupération des infos (avenant) pour l'affichage du formulaire à l'édition
 *  draft => correspond à la vue attribution ajout d'avenants
 **/
function infoAmendment(lines, draft = false) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();
    lines.forEach(line => {
        var field = line.attributes.class.value;
        var value = line.getAttribute('data-value');
        switch (field) {
            case "idAmendment d-none":
                formData.append('idAmendment', value);
                break;
            case "type":
                formData.append('type', value);
                break;
            case "amount text-end":
                formData.append('amount', value);
                break;
            case "description":
                formData.append('description', value);
                break;
            case "date":
                formData.append('date', value);
                break;
            case "amendmentClient":
                formData.append('amendmentClient', value);
                break;
            case "provisional":
                if (value == 1)
                    formData.append('provisional', 1);
                else
                    formData.append('provisional', 0);
                break;
            case "enabled":
                if (value == 1)
                    formData.append('enabled', 1);
                else
                    formData.append('enabled', 0);
                break;
        }
    });
    if (draft === true) {
        formData.append('draft', 1);
    }
    return formData;
}


/**
 *  Action d''ajout d'avenants visuellement ou en BDD suivant la vue
 **/
function saveAmendment(form) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case "admin_amendment_form_type":
                formData.append('type', value);
                break;
            case "admin_amendment_form_amount":
                formData.append('amount', value);
                break;
            case "admin_amendment_form_description":
                formData.append('description', value);
                break;
            case "admin_amendment_form_date":
                formData.append('date', value);
                break;
            case "admin_amendment_form_provisional":
                if (document.getElementById('admin_amendment_form_provisional').checked == true)
                    formData.append('provisional', 1);
                else
                    formData.append('provisional', 0);
                break;
            case "admin_amendment_form_subcontractorLot":
                formData.append('subcontractorLot', value);
                break;
            case "admin_amendment_form_amendmentClient":
                formData.append('amendmentClient', value);
                break;
            case "admin_amendment_form_enabled":
                if (document.getElementById('admin_amendment_form_enabled').value == 1)
                    formData.append('enabled', 1);
                else
                    formData.append('enabled', 0);
                break;
        }
    });

    return formData;
}

/**
 *  Action d''ajout d'avenants Client
 **/
function saveAmendmentClient(form) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case "amendment_client_amount":
                formData.append('amount', value);
                break;
            case "amendment_client_description":
                formData.append('description', value);
                break;
            case "amendment_client_date":
                formData.append('date', value);
                break;
            case "amendment_client_comment":
                formData.append('comment', value);
                break;
            case "amendment_client_enabled":
                if (document.getElementById('amendment_client_enabled').value == 1)
                    formData.append('enabled', 1);
                else
                    formData.append('enabled', 0);
                break;
        }
    });

    return formData;
}

/**
 *  Action d''ajout d'avenants en BDD en ajax
 **/
function saveAllAmendment(listAccordionItems) {
    // Construction d'un array à index - name_propriété => value
    let formData = new FormData();
    listAccordionItems.forEach(function (item) {

        // Récupération de l'id de l'attribution + le budget P1
        let id = item.attributes.id.value;
        let budgetP1 = item.querySelector("input").value;

        let datas = new Object();
        datas.assigment = id;
        datas.budgetP1 = budgetP1;
        datas.amendments = new Object();

        // Récupération du chaque tableau
        let tbody = item.querySelector("tbody").children;

        // Parcourt du tableau pour récuperer chaque ligne
        tbody.forEach(function (tr, index) {
            let td = tr.children;
            let amendment = new Object();

            if (tr.attributes["data-value"]) {
                td.forEach(function (item) {
                    let field = item.attributes["class"].value;
                    let value = "";
                    if (item.attributes["data-value"] !== undefined) value = item.attributes["data-value"].value;
                    switch (field) {
                        case "idAmendment d-none":
                            amendment.idAmendment = value;
                            break;
                        case "type":
                            amendment.type = value;
                            break;
                        case "amount text-end":
                            amendment.amount = value;
                            break;
                        case "description":
                            amendment.description = value;
                            break;
                        case "amendmentClient":
                            amendment.amendmentClient = value;
                            break;
                        case "date":
                            amendment.date = value;
                            break;
                        case "provisional":
                            if (value == 1)
                                amendment.provisional = value;
                            else
                                amendment.provisional = 0;
                            break;
                        case "enabled":
                            if (value == 1)
                                amendment.enabled = value;
                            else
                                amendment.enabled = 0;
                            break;
                    }
                });
                datas.amendments[index] = amendment;
            }
        });

        formData.append(id, JSON.stringify(datas));
    });
    return formData;
}


//==========================================  FIN Gestion Des avenants via Ajax =======================================//


//========================================  Gestion des échéances prévisionnelles via Ajax =============================================//

/**
 *  Action d'ajout d'échéances prévisionnelles visuellement ( !! pas en BDD avant validation finale)
 **/
function saveLotFinancialSchedule(form) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "lot_financial_schedule_name":
                formData.append('name', value);
                break;
            case "lot_financial_schedule_forecastDate":
                formData.append('forecastDate', value);
                break;
            case "lot_financial_schedule_amount":
                formData.append('amount', value);
                break;
            case "lot_financial_schedule_percent":
                formData.append('percent', value);
                break;
            case "lot_financial_schedule_comment":
                formData.append('comment', value);
                break;
            case "lot_financial_schedule_status":
                if (value == 1 || value == 2)
                    formData.append('status', value);
                else
                    formData.append('status', 0);
                break;
            case "lot_financial_schedule_enabled":
                if (value == 1)
                    formData.append('enabled', value);
                else
                    formData.append('enabled', 0);
                break;
            case "lot_financial_schedule_estimatedAmount":
                formData.append('estimatedAmount', value);
                break;
            case "lot_financial_schedule_p2":
                formData.append('p2', value);
                break;
        }
        formData.append('viewArraySchedule', 1); // Permet de savoir la vue a afficher en retour dans le controller (tableau des échéances)
    });

    return formData;
}


/**
 *  Récupération des infos (échéances prévisionnelle) pour l'affichage du formulaire à l'édition
 **/
function infoLotFinancialSchedule(lines) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    lines.forEach(line => {
        var field = line.attributes.class.value;
        var value = line.getAttribute('data-value');
        switch (field) {
            case "idLotFinancialSchedule d-none":
                formData.append('idLotFinancialSchedule', value);
                break;
            case "name":
                formData.append('name', value);
                break;
            case "forecastDate":
                formData.append('forecastDate', value);
                break;
            case "amount text-end":
                formData.append('amount', value);
                break;
            case "estimatedAmount d-none":
                formData.append('estimatedAmount', value);
                break;
            case "percent text-end":
                formData.append('percent', value);
                break;
            case "comment":
                formData.append('comment', value);
                break;
            case "status":
                if (value == 1 || value == 2)
                    formData.append('status', value);
                else
                    formData.append('status', 0);
                break;
            case "enabled":
                if (value == 1)
                    formData.append('enabled', value);
                else
                    formData.append('enabled', 0);
                break;
        }
    });
    return formData;
}


/**
 * Fonction permettant de valider l'ensemble des échéances prévisionnelles en BDD
 * @param  idTable
 */
function saveAllLotFinancialSchedule(idTable) {

    var arrayTmp = {};

    $('#' + idTable + ' tr').each(function (i) {
        var tmp = {};
        $.each(this.cells, function (index) {
            let element = $(this)[0];
            let field = element.attributes["class"].value;
            let value = "";
            if (element.attributes["data-value"] !== undefined) value = element.attributes["data-value"].value;

            switch (field) {
                case "idLotFinancialSchedule d-none":
                    tmp.idLotFinancialSchedule = value;
                    break;
                case "name":
                    tmp.name = value;
                    break;
                case "forecastDate":
                    tmp.forecastDate = value;
                    break;
                case "amount text-end":
                    tmp.amount = value;
                    break;
                case "estimatedAmount d-none":
                    tmp.estimatedAmount = value;
                    break;
                case "percent text-end":
                    tmp.percent = value;
                    break;
                case "comment":
                    tmp.comment = value;
                    break;
                case "enabled":
                    if (value == 1)
                        tmp.enabled = value;
                    else
                        tmp.enabled = 0;
                    break;
                case "status":
                    if (value == 1 || value == 2)
                        tmp.status = value;
                    else
                        tmp.status = 0;
                    break;
            }
        });
        arrayTmp[i] = tmp;
    });

    const arrayValues = {};
    arrayValues.financialSchedule = arrayTmp;
    return arrayValues;
}

//==========================================  FIN Gestion des échéances Projet via Ajax =======================================//


/**
 *  Récupération des infos (échéances projet) pour l'affichage du formulaire à l'édition
 **/
function infoProjectFinancialSchedule(lines) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();
    lines.forEach(line => {
        var field = line.attributes.class.value;
        var value = line.getAttribute('data-value');
        switch (field) {
            case "idProjectFinancialSchedule d-none":
                formData.append('idProjectFinancialSchedule', value);
                break;
            case "name":
                formData.append('name', value);
                break;
            case "forecastDate":
                formData.append('forecastDate', value);
                break;
            case "paymentConditions":
                formData.append('paymentConditions', value);
                break;
            case "amount text-end":
                formData.append('amount', value);
                break;
            case "percent text-end":
                formData.append('percent', value);
                break;
            case "comment":
                formData.append('comment', value);
                break;
            case "enabled":
                if (value == 1)
                    formData.append('enabled', value);
                else
                    formData.append('enabled', 0);
                break;
        }
    });
    return formData;
}


/**
 *  Action d'ajout d'échéances projet visuellement ( !! pas en BDD avant validation finale)
 **/
function saveProjectFinancialSchedule(form) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_projectfinancialschedule_form_name":
                formData.append('name', value);
                break;
            case "admin_projectfinancialschedule_form_forecast_date_picker_input":
                formData.append('forecastDate', value);
                break;
            case "admin_projectfinancialschedule_form_percent":
                formData.append('percent', value);
                break;
            case "admin_projectfinancialschedule_form_comment":
                formData.append('comment', value);
                break;
            case "admin_projectfinancialschedule_form_paymentConditions":
                formData.append('paymentConditions', value);
                break;
            case "admin_projectfinancialschedule_status":
                if (value == 1 || value == 2)
                    formData.append('status', value);
                else
                    formData.append('status', 0);
                break;
            case "admin_projectfinancialschedule_form_enabled":
                if (value == 1)
                    formData.append('enabled', value);
                else
                    formData.append('enabled', 0);
                break;
        }
        formData.append('viewArraySchedule', 1); // Permet de savoir la vue a afficher en retour dans le controller (tableau des échéances)
    });

    return formData;
}


/**
 * Fonction permettant de valider l'ensemble des échéances projet en BDD
 * @param  idTable
 */
function saveAllProjectFinancialSchedule(idTable) {
    var arrayTmp = {};
    $('#' + idTable + ' tr.echeance').each(function (i) {
        var tmp = {};
        $.each(this.cells, function (index) {
            let element = $(this)[0];

            let field = "";
            if (element.attributes["class"] !== undefined) field = element.attributes["class"].value;

            let value = "";
            if (element.attributes["data-value"] !== undefined) value = element.attributes["data-value"].value;

            switch (field) {
                case "idprojectFinancialSchedule d-none":
                    tmp.idProjectFinancialSchedule = value;
                    break;
                case "name":
                    tmp.name = value;
                    break;
                case "forecastDate":
                    tmp.forecastDate = value;
                    break;
                case "paymentConditions":
                    tmp.paymentConditions = value;
                    break;
                case "amount text-end":
                    tmp.amount = value;
                    break;
                case "percent text-end":
                    tmp.percent = value;
                    break;
                case "comment":
                    tmp.comment = value;
                    break;
                case "enabled":
                    if (value == 1)
                        tmp.enabled = value;
                    else
                        tmp.enabled = 0;
                    break;
                case "status":
                    if (value == 1)
                        tmp.status = value;
                    else
                        tmp.status = 0;
                    break;
            }
        });
        arrayTmp[i] = tmp;
    });

    const arrayValues = {};
    arrayValues.financialSchedule = arrayTmp;
    return arrayValues;
}

//==========================================  FIN Gestion des échéances prévisionnelles via Ajax =======================================//


//=====================================  Gestion des filtes sur les dates de facture sur Dashbord via Ajax ==================================//

function saveDashboardfilter(form) {
    // Construction d'un array à index - name_propriété => value
    var arrayValues = new Object();

    arrayValues = {
        "startDate": "",
        "endDate": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_dashboardfilter_form_startDate":
                arrayValues.startDate = value;
                break;
            case "admin_dashboardfilter_form_endDate":
                arrayValues.endDate = value;
                break;
        }
    });

    return arrayValues;
}


//=====================================  Gestion des filtes sur les dates de mouvement de trésorerie sur Dashbord via Ajax ==================================//


function saveDashboardMouvementfilter(form) {
    // Construction d'un array à index - name_propriété => value
    var arrayValues = new Object();

    arrayValues = {
        "startDate": "",
        "endDate": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_dashboardmouvementfilter_form_startDate":
                arrayValues.startDate = value;
                break;
            case "admin_dashboardmouvementfilter_form_endDate":
                arrayValues.endDate = value;
                break;
        }
    });

    return arrayValues;
}

//=====================================  FIN Gestion des filtes sur les dates de mouvement de trésorerie sur Dashbord via Ajax ==================================//


//=====================================  Gestion export des factures sur une période via Ajax ==================================//

function saveExportfilter(form) {
    // Construction d'un array à index - name_propriété => value
    var formData = new FormData();

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_exportfilter_form_startDate":
                formData.append('startDate', value);
                break;
            case "admin_exportfilter_form_endDate":
                formData.append('endDate', value);
                break;
        }
    });

    return formData;
}

//===================================== FIN Gestion des filtes sur les dates de facture sur Dashbord via Ajax ==================================//


//=====================================  Récupération des infos du lot pour la création de facture via Ajax ==================================//

function subcontractorLotfilter(form) {
    // Construction d'un array à index - name_propriété => value
    let arrayValues = new Object();

    arrayValues = {
        "subcontractor": "",
        "lot": "",
    };

    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;
        switch (field) {
            case "admin_invoicesubcontractorlot_form_subcontractor":
                arrayValues.subcontractor = value;
                break;
            case "admin_invoicesubcontractorlot_form_lot":
                arrayValues.lot = value;
                break;
        }
    });
    return arrayValues;
}

//===================================== FIN Récupération des infos du lot pour la création de facture via Ajax ==================================//


/**
 * Méthode permettant de d'agir en fonction du boutton d'archivage
 * @param
 */
function putEventListenerOnArchiveBtn(btn, bool = false) {
    if (bool) {
        new mdb.Popconfirm(btn);
    }
    btn.addEventListener('confirm.mdb.popconfirm', async () => {
        const btnName = btn.attributes['name'].value;
        const path = btn.attributes['data-mdb-action'].value;
        let line = '';
        let type = undefined;
        let tableName = undefined;
        let arrayValues = '';
        let message = '';

        if (btnName === 'admin_document_refusal') {
            message = 'Le document a bien été refusé.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'client') {
            message = 'Le statut du client a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_delete_lot') {
            message = 'Le lot a bien été supprimé.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_archive_typelot') {
            message = 'Le statut du type de lot a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_archive_subcontractor') {
            message = 'Le statut du sous-traitant a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_archive_admin') {
            message = 'Le statut de l\' administrateur a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_archive_project') {
            message = 'Le statut d\'archivage du projet a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_delete_project') {
            message = 'Le projet a bien été supprimé.';
            line = btn.closest('tr');
        }

        if (btnName === 'admin_archive_project_show_interface') {
            message = 'Le statut d\'archivage du projet a bien été modifié.';
            line = btn.closest('tr');
            type = 'text';
        }

        if (btnName === 'admin_reject_invoice' || btnName === 'admin_accept_invoice') {
            message = 'La modification sur le statut de la facture à bien été prise en compte.';
            line = btn.closest('tr');
            type = 'text';
            tableName = line.closest('table');
        }

        if (btnName === 'admin_archive_lotFinancialSchedule') {
            alert = false;
            line = btn.closest('tr');
            type = 'text';
            tableName = line.closest('table');
            arrayValues = infoLotFinancialSchedule(line.children);
        }

        if (btnName === 'admin_archive_projectFinancialSchedule') {
            alert = false;
            line = btn.closest('tr');
            type = 'text';
            tableName = line.closest('table');
            arrayValues = infoProjectFinancialSchedule(line.children);
        }

        if (btnName === 'admin_archive_amendment') {
            line = btn.closest('tr');
            type = 'text';
            // Archivage Avenant via vue projet - onglet avenant
            if (btn.attributes["data-value"] !== undefined && btn.attributes["data-value"].value === "archiveByProject") {
                tableName = line.closest('table');
                message = 'L\'avenant a bien été modifié.';
            }
            // OU Archivage Avenant via la vue des attributions
            else {
                alert = false;
                tableName = line.closest('table');
                arrayValues = infoAmendment(line.children, true);
            }
        }

        // Archivage Avenant CLient via vue projet - onglet Avenants client
        if (btnName === 'admin_archive_amendment_client') {
            line = btn.closest('tr');
            type = 'text';
            tableName = line.closest('table');
            message = 'L\'avenant client a bien été modifié.';

        }

        const response = await fetchRequest(path, arrayValues, type);

        if (!response) {
            showAlerte('Impossible de répondre à votre demande pour le moment.');
        } else {
            if (alert) showAlerte(message, 'success');
            if (type === 'text') {
                updateArray(line, btnName, response, tableName);
            } else {
                updateArray(line, btnName, arrayValues, tableName);
            }
        }
    });
}


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à la suppression de donnée
 * @param btn
 */
function putEventListenerOnDelete(btn) {
    new mdb.Tooltip(btn, {container: 'body'});
    btn.addEventListener('click', async () => {
        button = btn;

        const btnName = btn.attributes['name'].value;
        const path = btn.attributes['data-mdb-action'].value;
        let line = '';
        let type = undefined;
        let tableName = undefined;
        let arrayValues = '';
        let message = '';

        if (btnName === 'admin_project_delete_avenant') {
            type = 'text';
            message = 'L\'avenant a bien été supprimé.';
            line = btn.closest('.row');
        } else if (btnName === 'admin_delete_avenant') {
            message = 'L\'avenant a bien été supprimé.';
            line = btn.closest('tr');
        }

        const response = await fetchRequest(path, arrayValues, type);

        if (!response) {
            showAlerte('Impossible de répondre à votre demande pour le moment.');
        } else {
            showAlerte(message, 'success');
            if (type === 'text') {
                updateArray(line, btnName, response, tableName);
            } else {
                updateArray(line, btnName, arrayValues, tableName);
            }
        }
    })
}


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à l'export de donnée
 * @param btn
 */
function putEventListenerOnExport(btn) {
    new mdb.Tooltip(btn, {container: 'body'});
    btn.addEventListener('click', async () => {
        button = btn;

        const path = btn.attributes['data-mdb-action'].value;
        let type = undefined;
        let line = '';
        let tableName = '';
        let div = btn.closest('div');
        let form = div.closest('form');
        let arrayValues = saveDashboardMouvementfilter(form);
        let formJSON = JSON.stringify(arrayValues);
        const btnName = btn.attributes['name'].value;

        const response = await fetchRequest(path, formJSON, type);

        if (!response)
            showAlerte('Impossible de répondre à votre demande pour le moment.');
        else {
            updateArray(line, btnName, response, tableName);

        }
    })
}

window.putEventListenerOnExport = putEventListenerOnExport;


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à l'affichage d'une modal pour la consultation de données
 * @param btn
 */
function putEventListenerOnModalShow(btn) {
    new mdb.Tooltip(btn, {container: 'body'})
    btn.addEventListener('click', () => {
        fetch(`${btn.attributes['data-mdb-action'].value}`)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    //console.error("Status code :"+response.status+" Status message :"+response.statusText);
                    throw Error("Status code :" + response.status + " Status message :" + response.statusText);
                }
            })
            .then(data => {
                showModalEl.querySelector('.modal-content').innerHTML = data;
                // Modal edit/create
                showModalEl.getElementsByClassName('ticadmin-show-modal-btn').forEach(btn => {
                    putEventListenerOnModalShowBis(btn);
                });

            })

            .catch(err => console.log(err));
        showModal.show();
    })
}


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à l'affichage d'une modal (2e modal imbriquée dans le 1er ) pour la consultation de données
 * @param btn
 */
function putEventListenerOnModalShowBis(btn) {
    new mdb.Tooltip(btn, {container: 'body'})
    btn.addEventListener('click', (event) => {
        fetch(`${btn.attributes['data-mdb-action'].value}`)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw Error("Status code :" + response.status + " Status message :" + response.statusText);
                }
            })
            .then(data => {
                showModalElBis.querySelector('.modal-content').innerHTML = data;
                // Affichage de la 2e modal
                showModalBis.show();
            })
            .catch(err => console.log(err));
        showModal.show();
    })
}


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à l'affichage d'une modal pour le listage de données
 * @param btn
 */
function putEventListenerOnModalList(btn) {
    new mdb.Tooltip(btn, {container: 'body'});
    btn.addEventListener('click', () => {
        fetch(`${btn.attributes['data-mdb-action'].value}`)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw Error("Status code :" + response.status + " Status message :" + response.statusText);
                }
            })
            .then(data => {
                showModalEl.querySelector('.modal-content').innerHTML = data;
                showModalEl.querySelectorAll('.ticadmin-actions').forEach(div => {
                    setAdminDtActions(div);
                });

                // Prise en compte de la class popconfirm
                const popconfirmElements = showModalEl.querySelectorAll('.popconfirm-toggle');
                popconfirmElements.forEach((currentElement) => {
                    new mdb.Popconfirm(currentElement);
                });
                showModalEl.addEventListener('confirm.mdb.popconfirm', (event) => {
                    // Pas de fermeture de la modal si changement de statut de l'échéance prévisionnelle
                    if (event.originalTarget?.id != "btn-archive")
                        showModal.hide();
                });

                // Traitement validation finale de toutes les échéances
                const btnSave = showModalEl.querySelector('#saveListModal');
                btnSave.addEventListener('click', async (event) => {
                    const btnName = btnSave.attributes['name'].value;
                    let path = btnSave.attributes['data-mdb-action'].value;
                    let type = undefined;
                    let formData = undefined;
                    let line = undefined;
                    let tableName = undefined;
                    let message = '';
                    $('.loading-div').css('display', 'block');
                    if (btnName === 'admin_lotFinancialSchedule_save_form') {
                        type = 'text';
                        let arrayValues = saveAllLotFinancialSchedule('tableLotFinancialSchedule');
                        formData = JSON.stringify(arrayValues);
                        line = btn.closest('tr');
                        tableName = btn.closest('.ticadmin-datatable');
                        message = 'Validation des échéances prévisionnelles bien prise en compte !';
                        // Vérification si les échéances sont bien égales à 100%
                        let boolVal = validateFinancialPercent("tableLotFinancialSchedule");
                        if (!boolVal) {
                            showAlerte('Veuillez vérifier l\'échéancier,<br/>le pourcentage cumulé n\'est pas égal à 100%');
                            return;
                        }
                    }

                    // Sauvegarde final des avenants + Budget P1
                    if (btnName === 'admin_amendment_save_form') {
                        // formData = saveP1(showModalEl.querySelectorAll('input'));
                        formData = saveAllAmendment(showModalEl.querySelectorAll('.accordion-item'));
                        type = 'text';
                        line = btn.closest('tr');
                        tableName = btn.closest('.ticadmin-datatable');
                        message = 'Validation des avenants bien prise en compte !';
                    }

                    if (btnName === 'admin_projectFinancialSchedule_save_form') {
                        type = 'text';
                        let arrayValues = saveAllProjectFinancialSchedule('tableProjectFinancialSchedule');
                        formData = JSON.stringify(arrayValues);
                        line = btn.closest('tr');
                        tableName = btn.closest('.ticadmin-datatable');
                        message = 'Validation des échéances projet bien prise en compte !';
                        // Vérification si les échéances sont bien égales à 100%
                        let boolVal = validateFinancialPercent("tableProjectFinancialSchedule");
                        if (!boolVal) {
                            showAlerte('Veuillez vérifier l\'échéancier,<br/>le pourcentage cumulé n\'est pas égal à 100%');
                            return;
                        }

                    }

                    const response = await fetchRequest(path, formData, type);

                    if (!response) {
                        showAlerte('Impossible de répondre à votre demande pour le moment.');
                    } else {
                        $('.loading-div').css('display', 'none');
                        $('#addElement').removeClass('visually-hidden');

                        if (btnName === 'admin_lotFinancialSchedule_save_form') {
                            if (btn.querySelector('.icon-as-btn').classList.contains("danger") == true) {
                                btn.querySelector('.icon-as-btn').classList.remove('danger');
                                btn.querySelector('.icon-as-btn').classList.add('success');
                            }
                        }
                        showModal.hide();
                        showAlerte(message, 'success');

                        // Refresh du badge (rouge ou vert) suivant si tout les échéances prévisionnelles ont été créé ou validé sur l'ensemble du projet - Onglet Trésorerie
                        if (btnName === 'admin_lotFinancialSchedule_save_form') {
                            var URL = tic_route.getProjectBadgeAjax;
                            URL = URL.replace("_ID_", $('#myTab').data('id'));
                            URL = URL.replace("_TYPE_", "tresorerie");
                            refreshBadgeProject(URL, '', "json", 'budgets-tab');
                        }

                        if (type === 'text') {
                            updateArray(line, btnName, response, tableName);
                        } else {
                            updateArray(line, btnName, formData, tableName);
                        }
                    }
                });
            })

            .catch(err => console.log(err));
        showModal.show();
    })
}

window.putEventListenerOnModalList = putEventListenerOnModalList;


/**
 * Méthode permettant de mettre un listener "onclick" sur un bouton dédié à l'édition/création
 * @param btn
 */
function putEventListenerOnModalEdit(btn) {
    new mdb.Tooltip(btn, {container: 'body'})
    btn.addEventListener('click', async () => {
        button = btn;

        $('#addElement').addClass('visually-hidden');
        $('.loading-div').css('display', 'block');

        let path = btn.attributes['data-mdb-action'].value;
        let formData = '';
        let type = "text";

        // Traitement particulier pour le form édition trésorie (échéance prévisionnelle)
        if (btn.getAttribute('data-value') === "lotFinancialScheduleEdit") {
            let lines = btn.closest('tr');
            formData = infoLotFinancialSchedule(lines.children);
        }
        // Traitement particulier pour le form édition avenant - vue des attributions
        if (btn.getAttribute('data-value') === "amendmentEdit") {
            let lines = btn.closest('tr');
            formData = infoAmendment(lines.children, true);
        }
        // Traitement particulier pour le form édition Budget (échéance projet)
        if (btn.getAttribute('data-value') === "projectFinancialScheduleEdit") {
            let lines = btn.closest('tr');
            formData = infoProjectFinancialSchedule(lines.children);
        }

        const response = await fetchRequest(path, formData, type);

        if (!response) {
            showAlerte('Impossible de répondre à votre demande pour le moment.');
        } else {
            editModalEl.querySelector('.modal-content').innerHTML = response;
            // Prise en compte de la class popconfirm
            const popconfirmElements = editModalEl.querySelectorAll('.popconfirm-toggle');
            popconfirmElements.forEach((currentElement) => {
                new mdb.Popconfirm(currentElement);
            });

            // Hide loader after close
            editModalEl.addEventListener('hidden.mdb.modal', (e) => {
                $('.loading-div').hide();
            });

            // editModalEl.addEventListener('confirm.mdb.popconfirm', () =>  editModalEl.querySelector('form').dispatchEvent(new Event('submit'))/*editModalEl.querySelector('form').submit()*/ );

            // Mise en forme CSS sur les input + focus + select
            initMDBInputs(editModalEl);

            /* Méthode de validation custom des input select */
            $('.needs-validation').on('submit', e => {
                validateSelect(e)
            });

            // Mise en forme CSS sur la validation du form + affichage icon (check ou non)
            // Catch submit
            const formEdit = editModalEl.querySelectorAll('.needs-validation');

            var unvalidState = editModalEl.getElementsByClassName("invalid-feedback");
            var validState = editModalEl.getElementsByClassName("valid-feedback");

            var faUnvalid = '<i class="fas fa-times"></i>';
            var faValid = '<i class="fas fa-check"></i>';

            for (let uvs of unvalidState) {
                uvs.insertAdjacentHTML('beforeend', faUnvalid);
            }
            for (let vs of validState) {
                vs.insertAdjacentHTML('beforeend', faValid);
            }

            /* View Edit sous-traitant - Récupération de la valeur du select Type de lot */
            var formSubcontractor = editModalEl.querySelector('#formSubcontractor');
            if (formSubcontractor !== 'undefined' && formSubcontractor !== null) {
                formSubcontractor.querySelector('#subcontractor_typeLotType').addEventListener('change', function () {
                    showLotNumbersByType(formSubcontractor, 'subcontractor_typeLotType', 'subcontractor_corpsEtat', true);
                });
            }

            /* View Attribution de lot - Récupération de la valeur du select Type de lot à la création de lot */
            var formLot = editModalEl.querySelector('#add-lot');
            if (formLot !== 'undefined' && formLot !== null) {
                // Suppression du disabled sur le select
                formLot.querySelector('#admin_lot_form_typeLotLibelle').removeAttribute('disabled');
                // Affichage des numeros de lot en fonction du type de lot
                //showLibelleLotsByType(formLot);
                showLotNumbersByType(formLot, 'admin_lot_form_typeLotType', 'admin_lot_form_typeLotLibelle', false);
                formLot.querySelector('#admin_lot_form_typeLotType').addEventListener('change', function () {
                    //showLibelleLotsByType(formLot);
                    showLotNumbersByType(formLot, 'admin_lot_form_typeLotType', 'admin_lot_form_typeLotLibelle', false);
                });

                // Modification du label P1 prévisionnel selon si checkboxe prévision est coché
                var div = editModalEl.querySelector("#forecastP1");
                let checkboxElement = $('#admin_lot_form_forecast');
                if (checkboxElement.is(':checked'))
                    $(div).find('.form-label')[0].textContent = "P1 prévisionnel";
                else
                    $(div).find('.form-label')[0].textContent = "P1";

                checkboxElement.change(function () {
                    if (this.checked)
                        $(div).find('.form-label')[0].textContent = "P1 prévisionnel";
                    else
                        $(div).find('.form-label')[0].textContent = "P1";

                });
            }

            /* View création d'une facture interface admin */
            // Création de Facture - Modifiation dynamique des propositions de lot suivant le sous-traitant selectionné
            let formInvoiceCreate = editModalEl.querySelector('#add-invoice');
            if (formInvoiceCreate !== 'undefined' && formInvoiceCreate !== null) {
                let projectId = formInvoiceCreate.getAttribute('data-projectId');
                const selectSubcontractor = document.getElementById("select-subcontractor");

                selectSubcontractor.addEventListener("change", function () {
                    // Récupération du sous-traitant selectionné
                    let subcontractorId = selectSubcontractor.querySelector("#admin_invoicesubcontractorlot_form_subcontractor").value;
                    let path = selectSubcontractor.getAttribute('data-url');
                    // Appel de la méthode qui permet de modifier le select de lot suiavnt retour Ajax
                    getChoicesLot(subcontractorId, projectId, path);
                });
            }


            // Permet de cacher les inputs suivant le checkbox (Accompte)
            var elementId = editModalEl.querySelector("#admin_assigmentlot_form_acomptePercentage");
            if (elementId !== 'undefined' && elementId !== null) {
                var div = editModalEl.querySelector("#acomptePercentage");
                let checkboxElement = $('#admin_assigmentlot_form_acompte');
                if (checkboxElement.is(':checked'))
                    setElementVisibility(true, elementId, div);

                else
                    setElementVisibility(false, elementId, div);


                checkboxElement.change(function () {
                    if (this.checked)
                        setElementVisibility(true, elementId, div);

                    else
                        setElementVisibility(false, elementId, div);
                });
            }

            // Permet de cacher l'input avenant client lié suivant la valeur du select type d'avenant
            let elementIdAmendmentType = editModalEl.querySelector("#admin_amendment_form_type");
            let elementIdAmendmentClient = editModalEl.querySelector("#admin_amendment_form_amendmentClient");

            if (elementIdAmendmentType !== 'undefined' && elementIdAmendmentType !== null) {

                if (elementIdAmendmentType.value == "avenant_client") {
                    elementIdAmendmentClient.closest('.select-business').style.display = "block";
                    elementIdAmendmentClient.setAttribute("required", true);
                } else {
                    elementIdAmendmentClient.closest('.select-business').style.display = "none";
                    elementIdAmendmentClient.removeAttribute("required");
                }

                elementIdAmendmentType.addEventListener('change', function (event) {
                    let value = event.target.value;
                    if (value != "avenant_client") {
                        elementIdAmendmentClient.closest('.select-business').style.display = "none";
                        elementIdAmendmentClient.removeAttribute("required");
                    } else {
                        elementIdAmendmentClient.closest('.select-business').style.display = "block";
                        elementIdAmendmentClient.setAttribute("required", true);
                    }
                });
            }
        }
        editModal.show();
    })
}

window.putEventListenerOnModalEdit = putEventListenerOnModalEdit;

//===========================  Gestion modification d'un document administratif via Ajax - Espace sous-traitant ===========================//

/**
 *  Action de modification du document administratif en BDD en ajax
 **/
function editDocument(form) {
    // Construction d'un array à index - name_propriété => value
    const formData = new FormData();
    form.forEach(function (item) {
        var field = item.id;
        var value = item.value;

        switch (field) {
            case "document_edit_file":
                formData.append('file', item.files[0]);
                break;
        }
    });
    return formData;
}

//===========================  FIN Gestion modification d'un document administratif via Ajax - Espace sous-traitant ===========================//


/**
 * Methode d'affichage de nouvelle données avec requête ajax
 * @param line
 * @param nameArray
 * @param arrayValues
 */
function updateArray(line, nameArray, arrayValues, tableName = null) {

    if (nameArray == "admin_document_form" || nameArray === 'admin_refusaldocument_form') {
        const tbody = line.closest('tbody');
        const index = Array.prototype.indexOf.call(tbody.children, line);
        var el = $.parseHTML(arrayValues);
        const tr = el[0];

        if (line.classList.contains('first')) {
            const firstTd = line.children[0];
            tr.insertAdjacentElement('afterbegin', firstTd);
            tr.classList.add('first');
        }
        line.replaceWith(tr);
        const children = tbody.children;
        const newTr = children[index];


        // Refresh du badge (rouge ou vert) suivant si tout les document ont été validé ou non sur l'ensemble du projet - Onglet Attestation
        var URL = tic_route.getProjectBadgeAjax;
        URL = URL.replace("_ID_", $('#myTab').data('id'));
        URL = URL.replace("_TYPE_", "document");
        refreshBadgeProject(URL, '', "json", 'documents-tab');

        // Refresh du badge de l'accordeon Attestation - l'ensemble des documents sur le lot
        let accordion = tbody.closest('.accordion-item');
        // récupération de l'id du lot
        let idLot = accordion.getAttribute("data-id");
        var URL = tic_route.getLotBadgeAjax;
        URL = URL.replace("_ID_", idLot);
        refreshBadgeProject(URL, '', "json", 'accordion-lot-' + idLot);

        setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);
    }
    if (nameArray === 'document_edit') {
        // Mise à jour de l'onglet avec données misent à jour
        $('#documents').html(arrayValues);
    }
    else if (nameArray == 'client') {
        if (line === '') {
            const dtInstance = $('.tic-datatable').DataTable();
            let el = $.parseHTML(arrayValues);
            dtInstance.row.add(el[0]).draw();

            // Activation des actions des btn
            setListenersOnActions(el[0].querySelector('.ticadmin-actions').children)

        } else {
            const tbody = document.querySelector('tbody');
            const index = Array.prototype.indexOf.call(tbody.children, line);
            var el = $.parseHTML(arrayValues);
            line.replaceWith(el[0]);
            const children = tbody.children;
            const newTr = children[index];
            putEventListenerOnModalEdit(newTr.querySelector('.ticadmin-actions').querySelector('#btnEdit'));
            putEventListenerOnModalShow(newTr.querySelector('.ticadmin-actions').querySelector('#btnShow'));
            putEventListenerOnArchiveBtn(newTr.querySelector('.ticadmin-actions').querySelector('.btn-archive'), true);
        }

    }
    else if (nameArray === 'admin_lot_form') {
        let idTable = '';
        switch (tableName) {
            case 'etudes':
                idTable = 'ticadmin-datatable-etudes';
                break;
            case 'exécution':
                idTable = 'ticadmin-datatable-exécution';
                break;
            case 'divers':
                idTable = 'ticadmin-datatable-divers';
                break;
            default:
                showAlerte('Imposible d\'insérer les nouvelles données dans le tableau !');
                break;
        }

        // Reload table via Ajax
        var URL = tic_route.getProjectAssignmentLot;
        URL = URL.replace("_TYPE_", tableName)
        URL = URL.replace("_ID_", $('#projet-id-holder').val())
        $.ajax(URL).done(function (htmlContent) {
            $('#' + idTable).html(htmlContent);


            let table = document.querySelector('#' + idTable);
            // Initialisation des boutons actions du tableau
            table.getElementsByClassName('ticadmin-archive-btn').forEach(btn => {
                putEventListenerOnArchiveBtn(btn);
            });
            table.getElementsByClassName('ticadmin-edit-modal-btn').forEach(btn => {
                putEventListenerOnModalEdit(btn);
            });
            table.getElementsByClassName('ticadmin-list-modal-btn').forEach(btn => {
                putEventListenerOnModalList(btn);
            });
        })

    }
    else if (nameArray === 'admin_assigmentlot_form' || nameArray === "admin_amendment_save_form") {
        const table = line.closest('.ticadmin-datatable');
        const tbody = table.querySelector('tbody');
        const index = Array.prototype.indexOf.call(tbody.children, line);
        var el = $.parseHTML(arrayValues);
        line.replaceWith(el[0]);
        const newTr = tbody.children[index];

        const lines = tbody.querySelectorAll('tr:not(.inactive)');
        let totalP0 = parseFloat(0);
        let totalP1 = parseFloat(0);
        let p1Minusp0 = parseFloat(0);
        let amendment = parseFloat(0);
        let totalP2 = parseFloat(0);
        lines.forEach(line => {
            totalP0 += parseFloat(line.querySelector('.p0').getAttribute('data-value'));
            totalP1 += parseFloat(line.querySelector('.p1').getAttribute('data-value'));
            p1Minusp0 += parseFloat(line.querySelector('.p0Minusp1').getAttribute('data-value'));
            amendment += parseFloat(line.querySelector('.amendment').getAttribute('data-value'));
            totalP2 += parseFloat(line.querySelector('.p2').getAttribute('data-value'));
        });

        const elTotalP0 = table.querySelector('.total-value-p0');
        const elTotalP1 = table.querySelector('.total-value-p1');
        const elP1MinusP0 = table.querySelector('.total-value-p1-minus-p0');
        const elAdmendment = table.querySelector('.total-value-amendment');
        const elTotalP2 = table.querySelector('.total-value-p2');

        elTotalP0.setAttribute('data-value', totalP0);
        elTotalP0.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
        }).format(elTotalP0.getAttribute('data-value'));

        elTotalP1.setAttribute('data-value', totalP1);
        elTotalP1.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
        }).format(elTotalP1.getAttribute('data-value'));
        Math.sign(parseFloat(elTotalP1.getAttribute('data-value'))) === 1 ? elTotalP1.style.color = 'green' : Math.sign(parseFloat(elTotalP1.getAttribute('data-value'))) === -1 ? elTotalP0.style.color = 'red' : elTotalP0.style.color = 'black';

        elP1MinusP0.setAttribute('data-value', p1Minusp0);
        elP1MinusP0.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
        }).format(elP1MinusP0.getAttribute('data-value'));
        Math.sign(parseFloat(elP1MinusP0.getAttribute('data-value'))) === 1 ? elP1MinusP0.style.color = 'green' : Math.sign(parseFloat(elP1MinusP0.getAttribute('data-value'))) === -1 ? elP1MinusP0.style.color = 'red' : elP1MinusP0.style.color = 'black';

        elAdmendment.setAttribute('data-value', amendment);
        elAdmendment.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
        }).format(elAdmendment.getAttribute('data-value'));
        Math.sign(parseFloat(elAdmendment.getAttribute('data-value'))) === 1 ? elAdmendment.style.color = 'green' : Math.sign(parseFloat(elAdmendment.getAttribute('data-value'))) === -1 ? elAdmendment.style.color = 'red' : elAdmendment.style.color = 'black';

        elTotalP2.setAttribute('data-value', totalP2);
        elTotalP2.innerText = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR'
        }).format(elTotalP2.getAttribute('data-value'));
        Math.sign(parseFloat(elTotalP2.getAttribute('data-value'))) === 1 ? elTotalP2.style.color = 'green' : Math.sign(parseFloat(elTotalP2.getAttribute('data-value'))) === -1 ? elTotalP2.style.color = 'red' : elTotalP2.style.color = 'black';

        putEventListenerOnModalList(newTr.querySelector('.ticadmin-actions').querySelector('#btnList'));
        putEventListenerOnArchiveBtn(newTr.querySelector('.ticadmin-actions').querySelector('.btn-archive'), true);
        // Event sur le btn ajout d'une attribution que si il est disponible
        if (newTr.querySelector('.ticadmin-actions').querySelector('#btnAddAssigment') !== 'undefined') {
            putEventListenerOnModalEdit(newTr.querySelector('.ticadmin-actions').querySelector('#btnAddAssigment'));
        }
    }
    else if (arrayRequestsUpdateName.includes(nameArray)) {
        const dtInstance = $('.tic-datatable').DataTable();
        var row = dtInstance.row($(line));
        var lineIndex = row.index();
        var el = $(arrayValues).find('td');

        dtInstance.cells().every(function (rowNumber, colNumber) {
            if (rowNumber == lineIndex) {
                // mise a jour du display
                this.data($(el[colNumber]).html());
                // on regarde les data-order
                var order = $(el[colNumber]).attr('data-order');
                if (typeof order !== 'undefined' && order !== false) {
                    $(this.node()).attr('data-order', order);
                }
            }
        });

        dtInstance.row($(line)).invalidate().draw(false);
        var btns = $(dtInstance.row($(line)).node()).find('.ticadmin-actions').get(0);
        if (btns)
            setListenersOnActions(btns.children);

    }
    else if (nameArray === 'admin_delete_project') {
        const tbody = document.querySelector('tbody');
        const index = Array.prototype.indexOf.call(tbody.children, line);
        const dtInstance = $('.tic-datatable').DataTable();
        dtInstance.row(line).remove().draw();

    }
    else if (nameArray == 'admin_amendment_form' || nameArray == 'admin_archive_amendment') {
        if (line === '') {
            if (button.id === "btnAddAmendment") {
                line = button.closest('.row');
                var el = $.parseHTML(arrayValues);
                line.replaceWith(el[0]);
                document.querySelector('#avenants').querySelector('tbody').children.forEach(element => {
                    if (element.attributes['class'].value != 'table-active') {
                        setListenersOnActions(element.querySelector('.ticadmin-actions').children);
                    }
                });
                putEventListenerOnModalEdit(document.querySelector('#avenants').querySelector('#btnAddAmendment'));
                const tbody = document.querySelector('#avenants').querySelector('tbody');
                const tfooter = document.querySelector('#avenants').querySelector('tfoot');
                totalCalculation(tbody, tfooter);

                // Refresh du badge (rouge ou vert) suivant si tout les échéances prévisionnelles ont été créé ou validé sur l'ensemble du projet - Onglet Trésorerie
                var URL = tic_route.getProjectBadgeAjax;
                URL = URL.replace("_ID_", $('#myTab').data('id'));
                URL = URL.replace("_TYPE_", "tresorerie");
                refreshBadgeProject(URL, '', "json", 'tresorerie-tab');
            } else {
                const tbody = tableName.querySelector('tbody');
                tbody.insertAdjacentHTML('beforeend', arrayValues);
                const newTr = tbody.lastElementChild;
                setListenersOnActions(newTr.querySelector('.ticadmin-actions').children)
            }
        } else {
            const tbody = tableName.querySelector('tbody');
            const index = Array.prototype.indexOf.call(tbody.children, line);
            var el = $.parseHTML(arrayValues);
            const tr = el[0];
            if (line.classList.contains('first')) {
                const firstTd = line.children[0];
                const secondTd = line.children[1];
                tr.insertAdjacentElement('afterbegin', secondTd);
                tr.insertAdjacentElement('afterbegin', firstTd);
                tr.classList.add('first');
            }
            line.replaceWith(tr);
            const children = tbody.children;
            const newTr = children[index];
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);

            // Refresh du badge (rouge ou vert) suivant si tout les échéances prévisionnelles ont été créé ou validé sur l'ensemble du projet - Onglet Trésorerie
            // + recalcule du total
            if (tbody.getAttribute('id') == "amendementShowProject") {
                const tfooter = tableName.querySelector('tfoot');
                totalCalculation(tbody, tfooter);

                var URL = tic_route.getProjectBadgeAjax;
                URL = URL.replace("_ID_", $('#myTab').data('id'));
                URL = URL.replace("_TYPE_", "tresorerie");
                refreshBadgeProject(URL, '', "json", 'tresorerie-tab');
            }
        }

    }
    else if (nameArray == 'amendment_client' || nameArray == 'admin_archive_amendment_client') {
        if (line === '') {
            line = button.closest('.row');
            let el = $.parseHTML(arrayValues);
            line.replaceWith(el[0]);
            document.querySelector('#avenantsClient').querySelector('tbody').children.forEach(element => {
                if (element.attributes['class'].value != 'table-active') {
                    setListenersOnActions(element.querySelector('.ticadmin-actions').children);
                }
            });
            putEventListenerOnModalEdit(document.querySelector('#avenantsClient').querySelector('#btnAddAmendmentClient'));

        } else {
            const tbody = tableName.querySelector('tbody');
            const tfooter = tableName.querySelector('tfoot');
            const index = Array.prototype.indexOf.call(tbody.children, line);
            var el = $.parseHTML(arrayValues);
            const tr = el[0];
            line.replaceWith(tr);
            const children = tbody.children;
            const newTr = children[index];
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);

            // recalcule du montant total
            totalCalculation(tbody, tfooter);

            // Refresh du badge (rouge ou vert) mise à jour échéances projet - Onglet Echéancier
            var URL = tic_route.getProjectBadgeAjax;
            URL = URL.replace("_ID_", $('#myTab').data('id'));
            URL = URL.replace("_TYPE_", "budget");
            refreshBadgeProject(URL, '', "json", 'budgets-tab');
        }

        // Refresh du badge (rouge ou vert) mise à jour échéances projet - Onglet Echéancier
        var URL = tic_route.getProjectBadgeAjax;
        URL = URL.replace("_ID_", $('#myTab').data('id'));
        URL = URL.replace("_TYPE_", "budget");
        refreshBadgeProject(URL, '', "json", 'budgets-tab');

    }
    else if (nameArray === 'admin_delete_avenant' || nameArray === 'admin_project_delete_lotFinancialSchedule') {
        line.remove();
    }
    else if (nameArray === 'admin_project_delete_avenant') {
        var el = $.parseHTML(arrayValues);
        line.replaceWith(el[0]);
        document.querySelector('#avenants').querySelector('tbody').children.forEach(element => {
            setListenersOnActions(element.querySelector('.ticadmin-actions').children);
        });
        putEventListenerOnModalEdit(document.querySelector('#avenants').querySelector('#btnAddAmendment'));
    }
    else if (nameArray === 'admin_accept_invoice' || nameArray === 'admin_reject_invoice') {
        const tbody = tableName.querySelector('tbody');
        line = tableName.closest('.row');
        const index = Array.prototype.indexOf.call(tbody.children, line);
        var el = $.parseHTML(arrayValues);
        const tr = el[0];
        if (line.classList.contains('first')) {
            const firstTd = line.children[0];
            const secondTd = line.children[1];
            tr.insertAdjacentElement('afterbegin', secondTd);
            tr.insertAdjacentElement('afterbegin', firstTd);
            tr.classList.add('first');
        }
        line.replaceWith(tr);

        // Refresh du badge (rouge ou vert) suivant si toutes les factures ont été validé ou non sur l'ensemble du projet - Onglet Factures
        var URL = tic_route.getProjectBadgeAjax;
        URL = URL.replace("_ID_", $('#myTab').data('id'));
        URL = URL.replace("_TYPE_", "facture");
        refreshBadgeProject(URL, '', "json", 'factures-tab');


        // Refresh du badge (rouge ou vert) suivant si toutes les échéances prévisionnelles ont été validé ou non sur l'ensemble du projet - Onglet Trésorerie
        var URL = tic_route.getProjectBadgeAjax;
        URL = URL.replace("_ID_", $('#myTab').data('id'));
        URL = URL.replace("_TYPE_", "tresorerie");
        refreshBadgeProject(URL, '', "json", 'tresorerie-tab');

        document.querySelector('#factures').querySelector('tbody').children.forEach(element => {
            setListenersOnActions(element.querySelector('.ticadmin-actions').children);
        });
    }
    else if (nameArray === 'lot_financial_schedule' || nameArray === "admin_archive_lotFinancialSchedule") {
        const tbody = document.querySelector('.lotFinancialSchedule');
        const tfooter = document.querySelector('.footerLotFinancialSchedule');

        if (line === '') {
            // Insertion de la nouvelle ligne avant le total
            tbody.insertAdjacentHTML('beforeend', arrayValues);

            // le bouton d'ajout d'échéances plus accessible si % >= 100%
            let boolVal = validateFinancialPercent("tableLotFinancialSchedule", true);
            if (!boolVal)
                document.querySelector('#btnAddLotFinancialSchedule').classList.add("d-none");
            else
                document.querySelector('#btnAddLotFinancialSchedule').classList.remove("d-none");


            const newTr = tbody.lastElementChild;

            // Modification du total
            totalCalculation(tbody, tfooter, true);

            // Activation des actions des btn
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);

        } else {
            const index = Array.prototype.indexOf.call(tbody.children, line);
            let el = $.parseHTML(arrayValues);
            line.replaceWith(el[0]);
            const children = tbody.children;
            const newTr = children[index];

            // Modification du total
            totalCalculation(tbody, tfooter, true);

            // le bouton d'ajout d'échéances plus accessible si % >= 100%
            let boolVal = validateFinancialPercent("tableLotFinancialSchedule", true);
            if (!boolVal)
                document.querySelector('#btnAddLotFinancialSchedule').classList.add("d-none");
            else
                document.querySelector('#btnAddLotFinancialSchedule').classList.remove("d-none");

            // Activation des actions des btn
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);
        }

    }
    else if (nameArray === 'project_financial_schedule' || nameArray === "admin_archive_projectFinancialSchedule" || nameArray === "admin_projectfinancialschedule_form") {
        // Traitment visuellement edit/add et archivage ou non -  echéances projet
        const tbody = document.querySelector('.projectFinancialSchedule');
        const tfooter = document.querySelector('.footerProjectFinancialSchedule');

        if (line === '') {
            // Insertion de la nouvelle ligne avant le total
            tbody.insertAdjacentHTML('beforeend', arrayValues);

            // le bouton d'ajout d'échéances plus accessible si % >= 100%
            let boolVal = validateFinancialPercent("tableProjectFinancialSchedule", true);
            if (!boolVal)
                document.querySelector('#btnAddProjectFinancialSchedule').classList.add("d-none");
            else
                document.querySelector('#btnAddProjectFinancialSchedule').classList.remove("d-none");

            const newTr = tbody.lastElementChild;

            // Modification du total
            totalCalculation(tbody, tfooter, true, true);

            // Activation des actions des btn
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);

        } else {
            const index = Array.prototype.indexOf.call(tbody.children, line);
            let el = $.parseHTML(arrayValues);
            line.replaceWith(el[1]);
            const children = tbody.children;
            const newTr = children[index];


            // Modification du total
            totalCalculation(tbody, tfooter, true, true);

            // le bouton d'ajout d'échéances plus accessible si % >= 100%
            let boolVal = validateFinancialPercent("tableProjectFinancialSchedule", true);
            if (!boolVal)
                document.querySelector('#btnAddProjectFinancialSchedule').classList.add("d-none");
            else
                document.querySelector('#btnAddProjectFinancialSchedule').classList.remove("d-none");

            // Activation des actions des btn
            setListenersOnActions(newTr.querySelector('.ticadmin-actions').children);

        }
    }
    else if (nameArray === 'admin_projectFinancialSchedule_save_form') {
        // Traitment après la sauvegarde de tout le tableau echéances projet + return vers onglet échéanchier
        let ongletBudgets = document.querySelector('#budgets #projectFinancialSchedule');
        let el = $.parseHTML(arrayValues);
        ongletBudgets.replaceWith(el[2]);

        // Refresh du badge (rouge ou vert) mise à jour échéances projet - Onglet Echéancier
        var URL = tic_route.getProjectBadgeAjax;
        URL = URL.replace("_ID_", $('#myTab').data('id'));
        URL = URL.replace("_TYPE_", "budget");
        refreshBadgeProject(URL, '', "json", 'budgets-tab');

        ongletBudgets = document.querySelector('#budgets #projectFinancialSchedule');
        putEventListenerOnModalList(ongletBudgets.querySelector('#btnList'));
    }
    else if (nameArray === 'admin_dashboardfilter_form') {
        let obj = JSON.parse(arrayValues);
        const dtInstance = $('#dashboardAdmin').DataTable();
        dtInstance.rows().every(function (index) {
            var tr = $(this.node());
            var value = parseFloat(obj.data[tr.attr('data-id')]);
            tr.find('.totalInvoice').attr('data-value', value).html(
                new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(value)
            );
            this.invalidate();
        });
        dtInstance.draw();
    }
    else if (nameArray == "admin_dashboardmouvementfilter_form") {
        tableName.innerHTML = arrayValues;
    }
    else if (nameArray === 'admin_export_mouvement_form') {
        let date = new Date();
        let filename = "export_mouvements_" + moment(date).format('DDMMYYYYHHmmss') + ".csv";

        let processRow = function (row) {
            let finalVal = '';
            for (var j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                ;
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0)
                    finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (var i = 0; i < arrayValues['dataArray'].length; i++) {
            csvFile += processRow(arrayValues['dataArray'][i]);
        }

        let blob = new Blob([csvFile], {type: "text/csv"});
        let downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(blob);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    else if (nameArray === "admin_invoicesubcontractorlot_form") {
        let url = tic_route.getInvoiceAdd;
        url = url.replace("_IDSUBCONTRACTORLOT_", arrayValues);
        document.location.href = url
    }
    else location.reload();  /* TODO : update datable instead of reload page */
}

/**
 *
 *  Fonction permettant d'obtenir le code html d'un tableau d'éléments dans un array.
 * @param  form
 */
function getArrayFromHTMLElements(elements) {
    let array = [];
    for (let index = 0; index < elements.length; index++) {
        array[index] = elements[index].innerHTML;
    }

    return array;
}

/**
 * Fonction permettant d'appliquer des listeners sur un groupe de boutons
 * @param form
 *
 */
function setListenersOnActions(list) {
    list.forEach(function (item) {
        var field = item.id;
        switch (field) {
            case "btnShow":
                putEventListenerOnModalShow(item);
                break;
            case "btnEdit":
            case "btnAddAmendmentClient":
                putEventListenerOnModalEdit(item);
                break;
            case "btn-archive":
                putEventListenerOnArchiveBtn(item, true);
                break;
            case "btnDelete":
                putEventListenerOnDelete(item);
                break;
            case "btnList":
                putEventListenerOnModalList(item);
                break;
        }
    });
}

window.setListenersOnActions = setListenersOnActions;


/**
 * Methode permettant de gérer l'envoie de formulaire
 */
async function handleFormRequest(event, form) {
    const btn = button;
    form.classList.add('was-validated');
    if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }

    if (form.getAttribute('data-submit-type') == 'ajax') {
        event.preventDefault();
        var path = form.action;

        var formJSON = "";
        var arrayValues = [];
        var line = "";
        var type = undefined;
        var tableName = undefined;
        var message = '';
        let alert = true;

        if (form.name === "admin_subcontractorlotcomment_form") {
            const data = new FormData(document.getElementById("addElement"));

            $.ajax({
                type: "POST",
                url: form.action,
                data,
                processData: false,
                contentType: false,
                success: function (response) {
                    showAlerte('Le projet a bien été noté.', 'success')
                    const id = $(document).find('#admin_subcontractorlotcomment_form_id').val()
                    const row = $(document).find(`.subcontractor-lot-${id}`).find('.rating')
                    const currentRating = row.find('.star-rating-number')
                    currentRating.val(+response.rating <= currentRating.val() ? +response.rating + 1 : +response.rating)
                    window.ratingFunction(row)
                },
                error: function () {
                    showAlerte('Un erreur est survenue.');
                },
                complete: function () {
                    $('#ticadmin-edit-modal').modal('toggle')
                    $('#ticadmin-edit-modal').find('.modal-content').html('')
                }
            })
            return
        }

        // form de création d'un lot

        if (form.name === "admin_lot_form") {
            var path = btn.attributes['data-mdb-action'].value;
            var arrayValues = saveLot(form);
            formJSON = JSON.stringify(arrayValues);
            type = "text";
            tableName = form.querySelector('#admin_lot_form_typeLotType').selectedOptions[0].value;
            message = 'Le lot a bien été créé.'
        }
        //form d'attribution d'un lot
        if (form.name === "admin_assigmentlot_form") {
            var arrayValues = saveAssigment(form);
            formJSON = JSON.stringify(arrayValues);
            line = btn.closest('tr');
            type = "text";
            tableName = form
            message = 'Le lot a bien été attribué.';
        }
        // form de création de client
        if (form.name === "client") {
            var arrayValues = saveClient(form);
            formJSON = JSON.stringify(arrayValues);
            if (btn.id == 'btnEdit') {
                line = btn.closest('tr');
                message = 'Le client a bien été modifié.'
            } else {
                message = 'Le client a bien été créé.'
                line = '';
            }

            type = "text";
        }

        // form de validation document administratif
        if (form.name === "admin_document_form") {
            var path = btn.attributes['data-mdb-action'].value;
            var arrayValues = saveDocument(form);
            formJSON = JSON.stringify(arrayValues);
            line = btn.closest('tr');
            message = 'Le document a bien été validé.';
            type = "text";
        }

        // form de refus document administratif
        if (form.name === "admin_refusaldocument_form") {
            var path = btn.attributes['data-mdb-action'].value;
            var arrayValues = refusalDocument(form);
            formJSON = JSON.stringify(arrayValues);
            line = btn.closest('tr');
            message = 'Le document a bien été refusé.';
            type = "text";
        }

        // form de modification document administratif - sous-traitant
        if (form.name === "document_edit") {
            var path = btn.attributes['data-mdb-action'].value;
            formJSON = editDocument(form);
            line = btn.closest('tr');
            tableName = line.closest('table');
            message = 'Le document a bien été modifié.';
            type = "text";
        }

        if (form.name === 'admin_projectclosed_form') {
            line = btn.closest('tr');
            formJSON = closeProject(form);
            message = 'Le projet a bien été clôturé !';
            type = 'text';

        }

        if (form.name === 'account_validation') {
            line = btn.closest('tr');
            formJSON = accountValidationSubcontractor(form);
            if (formJSON.get('imageFile') === 'undefined') {
                showAlerte('Veuillez renseigner un fichier valide.');
                return;
            }
            message = 'Le sous-traitant a bien été validé.';
            type = 'text';
        }

        if (form.name === 'subcontractor') {
            line = btn.closest('tr');
            formJSON = saveSubcontractor(form);
            message = 'Le sous-traitant a bien été modifié.';
            type = 'text';

        }

        // form de création d'un avenant
        if (form.name == "admin_amendment_form") {
            type = "text";
            var formJSON = saveAmendment(form);
            if (btn.id == 'btnEdit') {
                line = btn.closest('tr');
                tableName = line.closest('table');
                message = 'L\'avenant a bien été modifié.';
            } else if (btn.id == "btnAddAmendment") {
                message = 'L\'avenant a bien été créé.';
                line = '';
            } else {
                tableName = btn.closest('.accordion-item').querySelector('table');
                message = 'L\'avenant a bien été créé.';
                line = '';
            }
        }

        // Form de création d'un avenant client
        if (form.name == "amendment_client") {
            type = "text";
            var formJSON = saveAmendmentClient(form);
            if (btn.id == 'btnEdit') {
                line = btn.closest('tr');
                tableName = line.closest('table');
                message = 'L\'avenant a bien été modifié.';
            } else {
                message = 'L\'avenant a bien été créé.';
                line = '';
            }
        }

        // form de création d'une échéance prévisionnelle
        if (form.name == "lot_financial_schedule") {
            formJSON = saveLotFinancialSchedule(form);
            type = "text";
            if (btn.id == 'btnEdit') {
                line = btn.closest('tr');
                tableName = line.closest('table');
                message = 'L\'échéance prévisionnelle a bien été modifié.';
            } else {
                message = 'L\'échéance prévisionnelle a bien été créé.';
                line = '';
            }
            type = "text";
        }

        // Form de création d'une échéance projet
        if (form.name == "admin_projectfinancialschedule_form") {
            formJSON = saveProjectFinancialSchedule(form);
            type = "text";
            if (btn.id == 'btnEdit') {
                line = btn.closest('tr');
                tableName = line.closest('table');
                message = 'L\'échéance du projet a bien été modifié.';
            } else {
                message = 'L\'échéance du projet a bien été créé.';
                line = '';
            }
        }

        // Filtre date des factures sur le dashboard admin
        if (form.name == "admin_dashboardfilter_form") {
            var arrayValues = saveDashboardfilter(form);
            formJSON = JSON.stringify(arrayValues);
            line = '';
            tableName = document.querySelector('#dashboardAdmin');
            type = "text";
            alert = false;
        }
        // Filtre date des mouvements de trésorerie sur le dashboard admin
        if (form.name == "admin_dashboardmouvementfilter_form") {
            var arrayValues = saveDashboardMouvementfilter(form);
            formJSON = JSON.stringify(arrayValues);
            line = '';
            tableName = document.querySelector('#dashbordTreso');
            type = "text";
            alert = false;
        }

        // form l'export CSV des factures sur une période
        if (form.name == "admin_exportfilter_form") {
            formJSON = saveExportfilter(form);
            line = '';
            type = "text";
            alert = false;
        }

        // Form intermediaire de selection de lot pour la création d'une facture via l'admin
        if (form.name == "admin_invoicesubcontractorlot_form") {
            let arrayValues = subcontractorLotfilter(form);
            formJSON = JSON.stringify(arrayValues);
            line = '';
            type = "text";
            alert = false;
        }

        $('#addElement').addClass('visually-hidden');
        $('.loading-div').css('display', 'block');

        const response = await fetchRequest(path, formJSON, type);

        $('.loading-div').css('display', 'none');
        $('#addElement').removeClass('visually-hidden');

        if (form.closest('#ticadmin-edit-modal')) {
            form.closest('#ticadmin-edit-modal').querySelector('#closeButtonModal').click();
        }

        if (!response) {
            showAlerte('Impossible de répondre à votre demande pour le moment.');
        } else {
            if (alert) showAlerte(message, 'success');
            if (type === 'text') {
                updateArray(line, form.name, response, tableName);
            } else {
                updateArray(line, form.name, arrayValues, tableName);
            }

        }
    }
}

window.handleFormRequest = handleFormRequest;


/**
 *  Méthode qui permet de vérifier le % cumulé des échéances (création de trésorie d'un lot)
 *  bouton d'ajout et de validation finale
 **/
function validateFinancialPercent(id, btnAdd = false) {

    var table = document.getElementById(id);
    var percent_cumule = parseFloat('0').toFixed(2);
    if (table.childElementCount > 0) {
        let children = table.children;
        for (var i = 0; i < children.length; i++) {
            if (children[i].querySelector('.enabled').getAttribute('data-value') == 1) {
                var percent = children[i].querySelector('.percent') ? parseFloat(children[i].querySelector('.percent').getAttribute('data-value')).toFixed(2) : 0;

                let result = parseFloat(percent) + parseFloat(percent_cumule);
                percent_cumule = parseFloat(result).toFixed(2);
            }
        }
    }
    // Vérification soit pour le bouton d'ajout d'échéance le % doit être >= 100%
    if (btnAdd) {
        if (percent_cumule >= 100)
            return false;
        else
            return true;
    }
    // Ou pour la validation total des échéances elles ne doivent pas être différentes de 100%
    else {
        if (percent_cumule != 100)
            return false;
        else
            return true;
    }
}

window.validateFinancialPercent = validateFinancialPercent;


/**
 *  Méthode qui permet re-calculer le montant total et le pourcentage total
 *  Dans les tableaux des avenants client/sous-traitant (vue show project) et le total des échéances (création de trésorie d'un lot)
 **/
function totalCalculation(tbody, tfooter, percent = false, echeancier = false) {
    let amount = parseFloat('0').toFixed(2);
    const elTotalAmount = tfooter.querySelector('.total-value-amount');

    const lines = tbody.querySelectorAll('tr');
    lines.forEach(line => {
        if (line.querySelector('.enabled').getAttribute('data-value') == 1) {
            let resultAmount = parseFloat(line.querySelector('.amount').getAttribute('data-value')) + parseFloat(amount);
            amount = parseFloat(resultAmount).toFixed(2);
        }
    });

    // Modification du total
    elTotalAmount.innerText = new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(amount);
    elTotalAmount.setAttribute('data-value', amount);
    elTotalAmount.classList.add('bold');


    if (percent) {
        let percent = parseFloat('0').toFixed(2);
        const elTotalPercent = tfooter.querySelector('.total-value-percent');
        lines.forEach(line => {
            if (line.querySelector('.enabled').getAttribute('data-value') == 1) {
                let resultPercent = line.querySelector('.percent') ? parseFloat(line.querySelector('.percent').getAttribute('data-value')) + parseFloat(percent) : percent;
                percent = parseFloat(resultPercent).toFixed(2);
            }
        });

        // Modification du total
        elTotalPercent.innerText = new Intl.NumberFormat('fr-FR').format(percent) + ' %';
        elTotalPercent.setAttribute('data-value', percent);
        elTotalPercent.classList.add('bold');

    }

}


/**
 * Methode d'affichage des lots selon le select du type
 * @param formSubcontractor
 */
function showLotNumbersByType(form, idSelectTypeLot = 'subcontractor_typeLotType', idSelectNumberLot = 'subcontractor_corpsEtat', multiple = true) {
    if (!multiple) {
        // on remet le disabled sur le select
        form.querySelector('#' + idSelectNumberLot).setAttribute('disabled', 'disabled');

        // suppression de l'input numero de lot si le type est modifié
        if (!multiple) form.querySelector('#' + idSelectNumberLot).value = "";
    }

    // Récupération de la valeur du type de lot
    var typeLot = form.querySelector('#' + idSelectTypeLot).value;

    // si le type de lot est défini, on supprime le disable
    if (typeLot) {
        form.querySelector('#' + idSelectNumberLot).removeAttribute('disabled');
    }

    // Tableau de correspondance des options à afficher
    var tabShowOptions = [];
    tabShowOptions['etudes'] = "0";
    tabShowOptions['exécution'] = "1";
    tabShowOptions['divers'] = "2";

    // Récupération du toutes les options du select
    var numbersLot = form.querySelector('#' + idSelectNumberLot).options;

    for (var i = 0; i < numbersLot.length; i++) {
        var libelleLot = numbersLot[i].firstChild.textContent;
        // Affichage des options du select en fonction du type de lot
        if (libelleLot.substring(0, 1) != tabShowOptions[typeLot]) {
            numbersLot[i].style.display = "none";
            numbersLot[i].setAttribute('hidden', 'hidden');
        } else {
            numbersLot[i].style.display = "block";
            numbersLot[i].removeAttribute('hidden');
        }
    }
}

// Export function
window.showLotNumbersByType = showLotNumbersByType;


function generateNumberProject(value, path) {
    var arrayValues = new Object();

    arrayValues = {
        "clientId": value,
    };

    // var path = '{{ path('admin_project_number_ajax') }}';

    // Envoie de l'id du client via Ajax
    fetch(path, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(arrayValues),
    })
        .then((response) => {
            // here you do what you want with response
            return response.json();
        })
        .then((data) => {
            if (data.success) {
                // Focus + Modification dynamique de l'input n° projet de type numeroClient - Numero - projet
                document.getElementById("project_number").focus();
                document.getElementById("project_number").classList.add("active");
                document.getElementById("project_number").value = data.data;
            }
        })
        .catch(err => console.log(err));
}

// Export function
window.generateNumberProject = generateNumberProject;


/**
 * Methode permettant la modifiaction dynamique du select de lot - création de facture interface admin
 * @param subcontractorId
 * @param projectId
 * @param path
 */
function getChoicesLot(subcontractorId, projectId, path) {
    let arrayValues = new Object();

    arrayValues = {
        "subcontractorId": subcontractorId,
        "projectId": projectId,
    };

    // Envoie de l'id du client via Ajax
    fetch(path, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(arrayValues),
    })
        .then((response) => {
            // here you do what you want with response
            return response.json();
        })
        .then((data) => {
            if (data.success) {
                // Modification dynamique des options du select lot suivant le retour AJax
                let selectLot = document.querySelector("#admin_invoicesubcontractorlot_form_lot");

                // Récupération de la 1er option du select "Veuillez choisir un élement..."
                let firstOption = selectLot.firstChild;
                let options = "<option value=''>" + firstOption.text + "</option>";

                for (var i = 0; i < data.data.length; i++) {
                    let item = data.data[i];
                    options += "<option value='" + item.idLot + "'>" + item.number + "</option>";
                }
                $(selectLot).find('option').remove().end().append(options);
            }
        })
        .catch(err => console.log(err));
}

// Export function
window.getChoicesLot = getChoicesLot;


//===========================  Fin all admin tables ===========================//


//=========================== PAGE project-form =================================//

/* Modal - Ajout d'un client Form create Project */
const adminCreateProjet = document.getElementById('stepper-project-form');

if (adminCreateProjet !== 'undefined' && adminCreateProjet !== null) {
    const editModalEl = document.getElementById('ticadmin-edit-modal');

    const editModal = new mdb.Modal(editModalEl);

    // Modal edit/create
    document.getElementsByClassName('ticadmin-edit-modal-btn').forEach(btn => {
        btn.addEventListener('click', () => {
            fetch(`${btn.attributes['data-mdb-action'].value}`)
                .then((response) => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        throw Error("Status code :" + response.status + " Status message :" + response.statusText);
                        //console.error("Status code :"+response.status+" Status message :"+response.statusText);
                    }
                })
                .then(data => {
                    editModalEl.querySelector('.modal-content').innerHTML = data;
                    // Mise en forme CSS sur les input + focus
                    editModalEl.querySelectorAll('.form-outline').forEach((formOutline) => {
                        new mdb.Input(formOutline).init();
                    });

                    // Mise en forme CSS sur la validation du form + affichage icon (check ou non)
                    const formEdit = editModalEl.querySelectorAll('.needs-validation');
                    Array.prototype.slice.call(formEdit).forEach((form) => {
                        form.addEventListener('submit', (event) => {
                            if (!form.checkValidity()) {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                            form.classList.add('was-validated');
                        }, false);
                    });

                    // Mise en forme CSS sur les input + focus + select
                    initMDBInputs(editModalEl);

                    /* Méthode de validation custom des input select */
                    $('.needs-validation').on('submit', e => {
                        validateSelect(e)
                    });

                    var unvalidState = editModalEl.getElementsByClassName("invalid-feedback");
                    var validState = editModalEl.getElementsByClassName("valid-feedback");

                    var faUnvalid = '<i class="fas fa-times"></i>';
                    var faValid = '<i class="fas fa-check"></i>';

                    for (let uvs of unvalidState) {
                        uvs.insertAdjacentHTML('beforeend', faUnvalid);
                    }
                    for (let vs of validState) {
                        vs.insertAdjacentHTML('beforeend', faValid);
                    }
                })
                .catch(err => console.log(err));
            editModal.show();
        })
    })
}
;


//========================== FIN PAGE project-form =================================//


//========================== Fonctions génériques ===================================//


/**
 *  Méthode de mise à jour du badge statut au niveau des tabs onglet projet
 **/
async function refreshBadgeProject(path, formData, type = "text", idOnglet) {
    const response = await fetchRequest(path, formData, type);
    let arrayTabOnglet = document.querySelectorAll('#' + idOnglet);

    if (!response) {
        showAlerte('Impossible de répondre à votre demande pour le moment.');
    }

    // modification de la class css suivant le retour de la requete ajax
    if (response.data == true) {
        arrayTabOnglet.forEach(function (item) {
            item.querySelector("span").classList.remove("bg-danger");
            item.querySelector("span").classList.add("bg-success");
        });
    } else {
        arrayTabOnglet.forEach(function (item) {
            item.querySelector("span").classList.remove("bg-success");
            item.querySelector("span").classList.add("bg-danger");
        });
    }
}


/**
 *  Méthode d'uniformisation des requêtes ajax
 **/
async function fetchRequest(path, formData, type = "json") {
    const response = await fetch(path, {
        method: "POST",
        body: formData
    });
    if (response.ok) {
        if (type === "text") {
            const text = await response.text().catch(() => {
                return false;
            });
            return text;
        }
        if (type === "json") {
            const json = await response.json().catch(() => {
                return false;
            });
            return json;
        }
    } else {
        return false;
    }
}

window.fetchRequest = fetchRequest;


/**
 *  Méthode d'affichage des alertes
 **/
function showAlerte(msg, color = 'danger') {
    let icon = '';
    if (color === 'success') {
        icon = '<i class="fas fa-check-circle"></i>';
    } else {
        icon = '<i class="fas fa-times-circle me-3"></i>';
    }

    const alert = document.createElement('div');
    alert.innerHTML = `<div class="d-flex justify-content-between">`
        + icon + msg +
        `<button
      type="button"
      class="btn-close"
      data-mdb-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
  `;
    alert.classList.add('alert', 'fade');

    document.body.appendChild(alert);

    const alertInstance = new mdb.Alert(alert, {
        color: color,
        stacking: false,
        hidden: true,
        width: '450px',
        position: 'top-center',
    });

    alertInstance.show();

    // fermeture automatique au bout d'un certain temps
    setTimeout(function () {
        alertInstance.close();
    }, 5000);
}

window.showAlerte = showAlerte;

// fermeture au bout de 5 secondes des alertes présentes dans la page
function closeAlerts() {
    document.querySelectorAll('.alert').forEach((elem) => {
        setTimeout(function () {
            var instance = mdb.Alert.getInstance(elem);
            if (instance)
                instance.close();
        }, 5000);
    });
}

closeAlerts();

/**
 * Affichage ou non d'un champ suivant le check
 * @param bool
 * @param element
 * @param div
 */
function setElementVisibility(bool, element, div = null) {

    if (div !== 'undefined' && div !== null) {
        if (bool) {
            div.classList.remove("invisible");
            div.classList.add("visible");
            element.setAttribute("required", true);

        } else {
            div.classList.remove("visible");
            div.classList.add("invisible");
            element.removeAttribute("required");
        }
    } else {
        if (bool) {
            element.style.visibility = "hidden";
            element.classList.remove("file-visible");
            element.classList.add("file-hidden");
            element.removeAttribute("required");
        } else {
            element.style.visibility = "visible";
            element.classList.remove("file-hidden");
            element.classList.add("file-visible");
            element.setAttribute("required", true);
        }
    }
}

window.setElementVisibility = setElementVisibility;


/** Méthode de validation custom des input select **/
function validateSelect(e) {
    const select = $('.needs-validation .select-business');
    select.each((i, el) => {
        if (!$(el).hasClass('input-hidden')) {
            if ($(el).find('select').val().length !== 0) {
                $(el).find('.valid-feedback').show();
                $(el).find('.invalid-feedback').hide();
                if ($(el).find('.select-input').hasClass("is-invalid")) {
                    $(el).find('.select-input').removeClass("is-invalid");
                }
                $(el).find('.select-input').addClass("is-valid");
            } else {
                $(el).find('.valid-feedback').hide();
                $(el).find('.invalid-feedback').show();
                if ($(el).find('.select-input').hasClass("is-valid")) {
                    $(el).find('.select-input').removeClass("is-valid");
                }
                $(el).find('.select-input').addClass("is-invalid");
            }
        }
    });
}

window.validateSelect = validateSelect;

//========================== FIN Fonctions génériques ===============================//


import '../vendor/teicee/form-bundle/assets/mdb-ticlist.js';
import '../vendor/teicee/form-bundle/assets/mdb-ticwysiwyg';
import './mdb-custom-date.js';
import './ticprint.js';

// Personnalisations téïcée
import '../vendor/teicee/core-bundle/assets/tic_scripts.js';
