/**
 * Personnalisations JS de téïcée.
 */

//var tic_debug = true;

function ticDebug(message) {
	if (tic_debug) console.log(message);
}

function ticTrans(token, def) {
	if (typeof tic_i18n !== 'object') return null;
	if (token in tic_i18n) return tic_i18n[token];
	return (typeof def !== 'undefined') ? def : token;
}

window.ticTrans = ticTrans;

/**
 * Popup de confirmation automatique
 */
jQuery('body').on('click', '.btn-confirm', function(e){
	var text = jQuery(this).data('confirm');
	// génération automatique du message si aucun spécifié en attribut
	if (! text) {
		var $icon = jQuery(this).children('.tic-icon');
		var result = ($icon.length) ? $icon.attr('class').match(/(bi|fa)\-([^\s""]+)/) : null;
		if (result) text = ticTrans('confirm-' + result.pop());
		if (! text) text = ticTrans('confirm-default');
		if (! text) text = "Confirmation ?";
	}
	// demande de confirmation
	if (! confirm(text)) { e.preventDefault(); return false; }
	// mise en place du lien réel protégé sur balise <a>
	var href = jQuery(this).data('href');
	if (href) jQuery(this).attr('href', href);
});


/**
 * Lancement des fonctions JavaScripts définies à l'initialisation de la page (scripts inline).
 */
jQuery(document).ready(function() {
	if (typeof ticStarter == "object") ticStarter.run();
});


