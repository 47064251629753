jQuery(document).ready(function() {
	let ticlists = $('.ticlist_list_form_items');
	ticlists.each( function(index){
		var list_id = $(this).attr('id');

		// Collection triable
		if( jQuery('#' + list_id + '_collection').attr('data-sortable') ) {
			jQuery('#' + list_id + '_collection > tbody').sortable();
		}

		// action d'ajout d'un prototype sur click du bouton
		jQuery('#collection-add-'+list_id).click(function() {
			var container = jQuery("#"+list_id+"[data-prototype]");
			// compte le nombre d'éléments déjà présents dans la collection pour incrémenter les futurs id
			var next = parseInt(jQuery("#"+list_id).attr('data-next'));

			if (container) {
				var collection = container.find('#' + list_id + '_collection > tbody');
				var prototype = container.attr('data-prototype');

                collection.append(prototype.replace(/__name__/g, '__name__'+next));

				jQuery('#'+list_id).trigger("add", [ list_id + '_collection_' + next ]);
                next = next +1;
				jQuery("#"+list_id).attr('data-next', next);
				return false;
			}
		});
	});

});

